// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: map-get($colors, 'datePickerSpanSelected'); //background
  color: map-get($colors, 'primary'); //text
  border: 1px solid map-get($colors, 'datePickerSelectedBorder'); //default styles include a border
}

.DateRangePicker_picker {
  z-index: 1000;
  background-color: #fff;
  position: absolute;  
}

@media all and (min-width: 1390px) {
  .DateRangePicker_picker {
    right: -11rem !important;
  }
}

.DateRangePickerInput_arrow {
  position: relative;
  bottom: 1px;
  right: 6px;
  font-size: 22px;
}

.CalendarDay__default {
  color: map-get($colors, 'primary');
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: map-get($colors, 'datePickerSelected');
  color: map-get($colors, 'datePickerSelectedTextColor');
  border-color: map-get($colors, 'datePickerSelectedBorder');
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: map-get($colors, 'datePickerSelected');
  border-color: map-get($colors, 'datePickerSelectedBorder');
}
.CalendarDay__selected_span:hover {
  background: map-get($colors, 'datePickerSelected');

  border-color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: map-get($colors, 'datePickerSelected');
}
.CalendarDay__active:hover {
  background: map-get($colors, 'datePickerSelected');
}
.DateInput_input__focused {
  border-bottom: .2rem solid map-get($colors, 'datePickerSelected');
}
.DayPickerNavigation_button {
  border: none;
  border-radius: 0;
  background: map-get($colors, 'datePickerSelected');
}
.DayPickerNavigation_button:hover {
  border: none;
  background: map-get($colors, 'primaryHoverBackground');
}
.DayPickerNavigation_svg__horizontal {
  fill: map-get($colors, 'black');
}

td.CalendarDay__outside {
  opacity: .4;
}
