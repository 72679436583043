.maintenance_label {
  font-size: 2.5rem;
  @media screen and (max-width: $mobileWidth) {
    font-size: 1.6rem;
  }
}

.maintenance_status {
  font-size: 1.6rem;
}

.maintenance_section_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 8.3rem;
  padding: 1.2em 2em;
  background-color: white;
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
  @media screen and (max-width: $mobileWidth) {
    padding: 1rem;
  }
  align-items: center;
  margin-bottom: 0.3rem;
  font-size: 1.6rem;

  .card-item {
    display: flex;
    position: relative;
    height: auto;
    min-height: 8vh;
    background: map-get($colors, 'white');
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($colors, 'tableHead');
    outline: none;
    flex-grow: 1;
    flex-basis: 22%;
    margin: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
  
    &:hover {
      cursor: pointer;
      border: 1px solid map-get($colors, 'tableHead');
      background: map-get($colors, 'blueLight');
    }
  
    &:focus {
      outline: none;
    }
    
    input {
      position: absolute;
      left: 0.3vw;
      top: 0.5vh;
    }
  
    &.selected {
      border: 1px solid map-get($colors, 'tableHead');
      background: map-get($colors, 'blueLight');
      transform: scale(0.90);
      font-weight: bold;
    }
  }

  .secondary-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 2rem;
  }
  
  .submit-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;

    button {
      min-width: 10vw;
      width: 10vw;
    }
  }
}
