.textArea {
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    background-color: map-get($colors, 'white');
    padding: 1rem;
    height: 25rem;
    width: 100%;
  }
  &__header {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }
  &__title {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem !important;
    font-weight: bold;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
  }
  &__reset {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  &__resetbutton {
    color: map-get($colors, 'blue1');
    font-size: 1.2rem !important;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
    background-color: transparent;
    border-color: transparent;
    padding-right: 0;
    .button_svg_spacing {
      margin-right: 0;
    }
  }
  &__resetbutton:hover {
    text-decoration: underline;
  }

  &__textAreaContainer {
    display: flex;
    flex: 3;
  }
  &__box {
    resize: none;
    height: 100%;
    padding: 1.2rem;
    width: 100%;
    font-size: 1.2rem !important;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
  }
  &__form {
    display: flex;
    flex: 1;
    resize: none;
    width: 100%;
    height: 100%;
  }

  &__footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    font-size: 1.2rem !important;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;

    &__limit-message {
      color: map-get($colors, 'error');
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .textArea {
    min-height: 20rem;
  }
}
