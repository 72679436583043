.breadcrumb {
  // OVERRIDES BOOTSTRAP
  background-color: map-get($colors, 'white') !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  // OVERRIDES BOOTSTRAP

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    color: map-get($colors, 'gray1');
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
  }

  &__item {
    border-bottom: 0.2rem solid transparent;
    padding-bottom: 0.3rem;

    &:hover {
      color: map-get($colors, 'action');
    }

    &--selected {
      color: map-get($colors, 'primary');
      font-weight: 600; // we don't use font-family medium, because there is an vertical offset
    }

    button {
      border: none;
      background: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__arrow {
    height: 1.2rem;
    margin: 0 1.5rem;
  }
}
