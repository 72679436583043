.doughnutChart {
  min-height: 20rem;
  min-width: 40rem;
  width: 100%;

  &__label {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -100px;
    line-height: 19px;
    text-align: center;
    z-index: 999999999999999;
  }
}

@media screen and (max-width: $mobileWidth) {
  .doughnutChart {
    max-width: 100%;
    min-width: 20rem;
    height: 100%;
  }
}
