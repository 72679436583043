.search-selected {
  margin-bottom: 1rem;

  &__placeholder {
    color: map-get($colors, 'gray500');
    font-family: $font-primary;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.6rem;
    border: 0.1rem solid map-get($colors, 'blue4');
    border-radius: 0.2rem;
    padding: 0.6rem 1rem;
    margin-bottom: 0.2rem;
    background-color: map-get($colors, 'mainBackground');
    font-size: 1.2rem;

    &-name {
      max-width: 17rem;
      line-height: 1.3rem;
      margin-right: 10px;
    }

    &-name svg {
      display: none;
    }
  }

  &__sub-entity {
    display: flex;
    color: map-get($colors, 'gray600');
    font-family: $font-primary;
  }

  &__close {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover path {
      color: map-get($colors, 'action');
    }
  }

  &__close-icon {
    height: 1rem;

    path {
      color: map-get($colors, 'gray600');
    }
  }
}
