.documents {
  display: flex;
  flex-wrap: wrap;

  &_marginFooter {
    margin: 0rem 2rem 5.8rem;
  }

  &_newInputFile {
    display: flex;
    padding-top: 1.5rem;
  }

  &_buttonCancelContainer {
    padding-left: 1rem;
  }

  &_fileInputSelected {
    display: flex;
    justify-content: space-around;
    background-color: map-get($colors, 'gray0');
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    border: solid;
    border-width: 2px;
    border-color: map-get($colors, 'primary');
  }

  &_buttonCancelFile {
    justify-content: flex-end;
    border: solid;
    border-width: 2px;
    border-color: map-get($colors, 'primary');
    background-color: map-get($colors, 'gray0');
  }

  &_table {
    .table {
      padding-left: 1rem;
    }
  }

  &_info {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.6rem;
    margin-top: 3rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &_title {
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 4rem;
  }

  &_subtitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    font-weight: 500;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2.6rem;
    margin-top: 3rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &_addDocumentButton {
    @media only screen and (max-width: 760px) {
      justify-content: flex-start !important;
    }

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 0;

    &-label {
      margin: 0 0 0 !important;
    }

    &-icon {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
      & path {
        fill: map-get($colors, 'primary');
      }
    }
  }

  &_card {
    @media only screen and (max-width: 760px) {
      display: inline-grid !important;
    }
    display: flex;
    flex-wrap: wrap;
  }

  &_input {
    margin-right: 1rem;
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-top: 12px;
    .SingleDatePicker,
    .SingleDatePickerInput {
      display: block;
      margin-top: 0.8rem;
    }
    .DateInput {
      width: 25rem;
    }
    .DateInput_input {
      font-size: 1.6rem;
    }
    .SingleDatePicker_picker {
      z-index: 2;
      right: 50% !important;
      transform: translateX(50%);
    }
    .CalendarDay {
      &[aria-disabled='true'] {
        background-color: map-get($colors, 'gray2');
        cursor: not-allowed;
      }
    }
  }

  &_input:nth-child(1),
  &_input:nth-child(2) {
    flex: 1 1 49%;
  }

  &_input:nth-child(3),
  &_input:nth-child(4),
  &_input:nth-child(5) {
    flex: 1 1 32%;
  }

  &_form-input {
    height: 4.8rem;
    margin-top: 8px;
  }

  &_emptyMsg {
    height: 100%;
    margin-top: 4rem;
    padding: 0 1rem;
    font-style: italic;
    text-align: center;
    font-size: 1.6rem;
  }

  &_searchBar_select {
    font-size: 1em;
    height: 4.8rem;

    [class$='-indicatorContainer'] {
      padding: 2px;
    }

    > [class$='-control'] {
      margin-top: 8px;
      min-height: 25px;
    }
  }

  &_form {
    margin-top: 2rem;
    padding-top: 2.4rem;
    padding-bottom: 3.4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
    color: map-get($colors, 'primary');
  }
}

.my-documents {
  &_info {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.6rem;
    margin-top: 3rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &_title {
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 4rem;
  }

  &_subtitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    font-weight: 500;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2.6rem;
    margin-top: 3rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &_input {
    font-size: 1.6rem;
    font-family: $font-primary;
    .SingleDatePicker,
    .SingleDatePickerInput {
      display: block;
      margin-top: 0.8rem;
    }
    .DateInput {
      width: 25rem;
    }
    .DateInput_input {
      font-size: 1.6rem;
    }
    .SingleDatePicker_picker {
      z-index: 2;
      right: 50% !important;
      transform: translateX(50%);
    }
    .CalendarDay {
      &[aria-disabled='true'] {
        background-color: map-get($colors, 'gray2');
        cursor: not-allowed;
      }
    }
  }

  .collapse-icon {
    padding: 1.6rem 1rem;
    border: 0.1rem solid map-get($colors, 'blue4');
    border-radius: 0.2rem;
    background-color: map-get($colors, 'white');
    &.close {
      svg {
        transition: 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
    svg {
      height: 0.8rem;
      transition: 0.3s ease-in-out;
    }
  }
}
