.organization-perimeter {
  font-size: 1.6rem;
  margin-bottom: 2em;
  
  &__search {
    @include materialBox;
    display: flex;

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
    }

    &-left {
      flex-grow: 1;
    }

    &-right {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 760px) {
        flex-direction: column;
      }
    }
  }

  &__collapse {
    margin-top: 3rem;

    .nested-collapse {

      &__body-inner {
        padding: 0;
      }
    }

    .nested-collapse__header, .nested-collapse__body {

      &.borderColorLvl--1 {
        border-color: map-get($colors, 'green2') !important;
      }
    }
  }

  &__label {
    color: map-get($colors, 'blue2');
    font-family: $font-primary-bold;
  }

  &__input {
    flex-grow: 0 !important;
    width: 63rem !important;
  }

  &__selected {
    margin-right: 5rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
  }

  &__btn {
    height: 5.0rem !important;
    width: 17.4rem !important;
    margin-right: 0.5rem !important;
    border-radius: 0.2rem;
    font-family: $font-primary-bold !important;
    font-size: 1.6rem !important;
    letter-spacing: 0.05rem;
    outline: 0 !important;
    margin-top: 2.4rem;
    margin-bottom: 2rem;

    &-icon {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
      & path {
        fill: map-get($colors, 'primary');
      }
    }

    &-title {
      margin: 0 0 0 !important;
    }
    &-add {
      width: 29rem !important;
      height: 5.0rem !important;
      margin-right: 0.5rem !important;
      border-radius: 0.2rem;
      font-family: $font-primary-bold !important;
      font-size: 1.6rem !important;
      letter-spacing: 0.05rem;
      outline: 0 !important;
      margin-top: 2.4rem;
      margin-bottom: 2rem;
      &-icon {
        width: 5rem;
        margin-right: 0 !important;
        & path {
          fill: map-get($colors, 'primary');
        }
      }
    }
  }
}
.input-group {
  @media only screen and (max-width: 760px) {
    width: 65%;
  }
}
