.sub-menu {
  &__item:nth-child(2) {
    grid-column: 4 / 6 !important;
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__item:nth-child(3) {
    grid-column: 6 / 8 !important;
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__item:nth-child(4) {
    grid-column: 8 / 10 !important;
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__item.true {
    border-bottom: 0.4rem solid map-get($colors, 'linkHover');
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.disabled {
      color: map-get($colors, 'gray1');
      cursor: not-allowed;
    }
  }
}
