.smart-select {
  background-color: map-get($colors, 'white');
  color: map-get($colors, 'primary');

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.8rem;
    padding: 2rem 1.7rem;
    border: 0.1rem solid transparent;
    cursor: pointer;

    &--disabled {
      background-color: map-get($colors, 'primaryDisabled');
      cursor: not-allowed;
    }

    &:hover {
      border-color: map-get($colors, 'linkHover');

      & .smart-select__select-arrow path {
        fill: map-get($colors, 'linkHover');
      }
    }

    &-label {
      font-family: $font-primary;
      font-size: 1.4rem;
    }
  }

  .search-selected {
    max-height: 150px;
    overflow: auto;

    @media screen and (max-height: 650px) {
      max-height: 100px;
    }

    @media screen and (max-height: 550px) {
      max-height: 80px;
    }
  }

  &__search {
    display: none;
    box-shadow: 0.4rem 0.4rem 1rem 0.4rem rgba(0, 0, 0, 0.44);
    z-index: 1000;
    max-height: 536px;
    overflow: auto;
    position: absolute;
  }

  &__select-arrow {
    display: flex;
    height: 1rem;
  }

  &__label {
    text-transform: lowercase;
  }

  // SWITCH MODE
  &.search-enabled {
    .smart-select__select-arrow--down {
      display: none;
    }

    .smart-select__search {
      display: block;
    }
  }

  &.search-disabled {
    .smart-select__select-arrow--up {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .smart-select {
    &__search {
      width: 95%;
    }
  }
}
