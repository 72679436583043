.checkbox {  
  cursor: pointer;

  &__label {
    margin: 0 4rem 0 2rem;
    font-size: 1.6rem;
  }

  @media screen and (max-width: $mobileWidth) {
    &__label {
        margin: 0 2rem 2rem;
      }
  }
}

.pretty {
  margin-right: 0;

  .svg-icon {
    background : map-get($colors, 'primary');

    & path {
      fill: map-get($colors, 'white');
    }
  }


  .state {

    label {

      &.is-bold {
        font-weight: bold;
      }

      &:before {
        border-color: map-get($colors, 'primary');
      }
    }      
  }
}

.pretty-form-checkbox {
  display: inline-block;
  margin-top: 1.5rem;
  margin-right: 2em;
}

.check-custom-container {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked, &:indeterminate {

      ~ .checkmark {
        background: map-get($colors, 'primary');
      }
    }

    &:checked {

      ~ .checkmark {
       
        svg {
          display: inline-block;
          width: 100%;

          path {
            fill: #fff;
          }
        }
      }
    }

    &:indeterminate {

      ~ .checkmark {

        &::before {
          content: '-';
          width: 100%;
          display: inline-block;          
          color: #fff;          
          font-weight: bold;
          text-align: center;
        }
      }      
    }
  }

  .checkmark {    
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid map-get($colors, 'primary');

    svg {
      position: relative;
      bottom: 2px;
      display: none;
    }
  }
}
