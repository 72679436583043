.activity-synthesis {
  padding-bottom: 7rem;
  padding: 0;

  &__title {
    margin-bottom: 1.75rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
  }

  &__chart-subtitle {
    font-family: $font-primary-medium;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 17px;
    color: map-get($colors, 'gray1');
  }

  &__flex {
    display: flex;
  }

  &__info {
    font-size: 1.3rem;
    width: 100%;
    color: map-get($colors, 'gray1');
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 0;
    flex-grow: 1;
    margin-right: 2.4rem;
  }

  &__first-row {
    min-height: 9.4rem;
    display: flex;

    & > .activity-synthesis__right {
      .activity-synthesis__export-button {
        margin-left: auto;
        box-sizing: border-box;
        height: 4.8rem;
        width: 16.6rem;

        &:hover {
          border-color: map-get($colors, 'action');
        }

        &--active {
          border-color: map-get($colors, 'blue6');
          background-color: map-get($colors, 'blue4');
        }
      }
    }
  }

  &__chart-title {
    font-size: 2rem;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 0;
    flex-grow: 1;
  }

  &__stacked-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
      0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
    margin-bottom: 1rem;
    padding: 1.3rem 0;
  }

  &__advanced {
    &__line-chart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40rem;
      width: 100%;
      background-color: #fff;
    }

    &__filter {
      width: 100%;
      padding: 1rem;
    }
  }

  &__year-range{
    margin: 2.6rem 0;
  }

  #activity-synthesis__detail {
    display: flex;
    margin-bottom: 2rem;
    .activity-synthesis__detail-button {
      margin-left: auto;
      box-sizing: border-box;
      height: 4.8rem;
      width: 16.6rem;
      background: map-get($colors, 'white');

      &:hover {
        border-color: map-get($colors, 'action');
      }

      &--active {
        border-color: map-get($colors, 'blue6');
        background-color: map-get($colors, 'blue4');
      }
    }
  }
  &__empty-space {
    height: 1.5rem;
    margin-bottom: 1rem;
  }
  #activity-synthesis__footer-label {
    height: 6.7rem;
  }
}

@media screen and (max-width: $tabletWidth) {
  .activity-synthesis {
    &__flex {
      flex-direction: column;
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__right {
      margin-top: $mt-small;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .activity-synthesis {
    padding: 0;

    &__title {
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }

    &__info {
      text-align: justify;
      font-size: 1.3rem;
      color: map-get($colors, 'gray1');
      margin-bottom: 1.8rem;
    }

    #activity-synthesis__detail {
      flex-direction: column;
      align-items: center;

      .activity-synthesis__detail-button {
        margin: auto;
      }
    }
    &__left {
      margin-right: 0;
    }

    &__first-row {
      flex-direction: column;
      align-items: center;

      & > .activity-synthesis__right {
        margin-bottom: 2rem;
        margin-right: 0;
        .activity-synthesis__export-button {
          margin: auto;
        }
      }
    }

    &__empty-space {
      display: none;
    }

    &__stacked-chart {
      min-height: 20rem;
      padding-top: 1.6rem;
    }

    &__advanced {
      &__line-chart {
        min-height: 20rem;
      }
    }
  }
}
