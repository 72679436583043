.layout-connection {
  @extend .bg-gray100;
  height: 100%;
  width: 100%;
  background-color: map-get($colors, 'white');

  .top-advice {
    margin: 4rem 4rem 2rem;
    padding-block-start: 1rem;
    color: map-get($colors, 'blue5');
    font-size: 1.2rem;
    font-family: $font-primary;
    line-height: 2rem;
    text-align: center;
    word-spacing: 0.1rem;
  }

  .dialog-box {
    padding: 3rem;
    z-index: 2;
    width: 65rem;
    margin: auto;

    @include respond-down-to-media-breakpoint(large) {
      padding: 3rem 1.5rem;
    }

    &__heading {
      color: map-get($colors, 'primary');
      font-size: 2.4rem;
      text-align: center;
      font-family: $font-primary-medium;

      @include respond-down-to-media-breakpoint(small) {
        font-size: 1.6rem;
      }
    }
      &__complementaryText {
          color: map-get($colors, 'primary');
          font-size: 1rem;
          text-align: center;
          margin-top: 3rem;
          font-family: $font-primary-medium;

          @include respond-down-to-media-breakpoint(small) {
              font-size: 1.6rem;
          }
      }
    &__info {
      color: map-get($colors, 'blue5');
      font-size: 1.2rem;
      font-family: $font-primary;
      text-align: center;
      line-height: 1.2rem;
      margin: 2.4rem 0;
    }
    .form {
      padding: 0;
      height: 100%;

      .form__label {
        color: map-get($colors, 'primary');
        font-family: $font-primary-medium;
      }

      .form_submit {
        color: map-get($colors, 'primary');
        font-family: $font-primary-medium;
      }
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .layout-connection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 0;
    .dialog-box {
      padding: 5rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .layout-connection {
    .dialog-box {
      width: 100%;
      padding: 4rem;
    }
  }
}
