.activity-bar-chart-section {
  &__bar-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
      0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
    margin-bottom: 1rem;
    padding: 1.3rem 0;
  }
}

@media screen and (max-width: $mobileWidth) {
  .activity-bar-chart-section {
    &__bar-chart {
      min-height: 20rem;
    }
  }
}
