.Dashboard {
  .fs-24 {
    font-size: 1.5rem;
  }

  &__welcome-msg {
    font-size: 1.6rem;
  }
  &__title {
    font-family: $font-primary-bold;
    font-size: 3.8rem;
  }
  &__body {
    .dashboard-card {
      @extend .box-shadow;
      border-radius: 1rem;
      background-color: white;
      overflow: hidden;
      .card-title {
        font-family: $font-primary-bold;
        margin-bottom: 0;
      }
      .dashboard-card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: map-get($colors, 'gray100');
        padding: 1rem 1.5rem;

        .buttons {
          display: flex;
          width: fit-content;
          font-family: $font-primary-medium;
          font-size: 1.5rem;
          .myDemandsManagement__creationButton {
            border-radius: 0.5rem 0 0 0.5rem;
          }
          button {
            background-color: transparent;
            border: 1px solid map-get($colors, 'borderFrameColor');
            position: relative;
            outline: 0;
            transition: all 0.3s;
            z-index: 1;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0%;
              height: 100%;
              background: map-get($colors, 'tabBorderHoverColor');
              transition: all 0.3s ease;
              z-index: -1;
            }

            &:hover {
              &:before {
                width: 100%;
              }
            }

            &.rightBtn {
              border-radius: 0 0.5rem 0.5rem 0;
              svg {
                width: 2.75rem;
                height: auto;
              }
              &.__secondary {
                background-color: map-get($colors, 'primaryButtonColor');
                &:before {
                  content: none;
                }
              }
              &.__tertiaire {
                padding: 0.75rem 1.25rem;
                border-radius: 0.5rem;
                &:before {
                  content: none;
                }
                &:hover {
                  background-color: map-get($colors, 'gray2');
                }
              }
            }
            &.leftBtn {
              border-radius: 0.5rem 0 0 0.5rem;
              padding: 0.75rem 1.25rem;
              display: flex;
              align-items: center;
              svg {
                margin-left: 1.25rem;
                width: 1.5rem;
                height: auto;
              }
            }
          }
        }
      }
      .dashboard-card-body {
        text-align: center;
        .kpis-dates {
          margin: 2rem 0;
          color: map-get($colors, 'gray600');
        }
        .elements {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3rem;
          padding: 0 1.5rem;
          .item {
            background-color: map-get($colors, 'gray100');
            border-radius: 1rem;
            width: 48%;
            padding: 3rem 1rem;
            .label {
              font-size: 1.25rem;
              font-family: $font-primary-medium;
              margin-bottom: 1.5rem;
            }
            .value {
              font-size: 3.5rem;
              font-family: $font-primary-bold;
              word-break: break-all;
            }
          }
        }
      }
    }

    &__left-bloc {
      .dashboard-demands,
      .dashboard-documents {
        .table {
          background-color: white;
          padding: 0.5rem;
          border-radius: 0 0 1rem 1rem;
          thead {
            .table__header-cell-kpi {
              text-align: start !important;
            }
          }
          tbody {
            td {
              text-align: start !important;
              padding-left: 0 !important ;
              > * {
                text-align: start !important;
              }
            }
          }
          thead,
          .table__separation {
            background-color: map-get($colors, 'gray2');
          }
          .demands-table__ButtonCell,
          .myDocuments__ButtonCell {
            button {
              border: none;
              background: transparent;
            }
          }

          .demands-table__numDemand,
          .demands-table__motif,
          .demands-table__adresseSitePrestation,
          .demands-table__statut,
          .demands-table__ButtonCell,
          .demands-table__name,
          .demands-table__docTypeLabel,
          .demands-table__createdAt,
          .demands-table__company,
          .demands-table__controlButtons {
            min-width: 10%;
          }
          td {
            font-size: 1.3rem;
          }
        }
      }
    }
    &__right-bloc {
      .env-indicators {
        box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
          0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
        border-radius: 1rem;
        overflow: hidden;
        .dashboard-env-indicators-header {
          display: flex;
          padding: 1rem 1.5rem;
          justify-content: space-between;
          align-items: center;
          background: map-get($colors, 'tabBorderHoverColor');
          h3 {
            font-size: 1.5rem;
            font-weight: bold;
          }
          .co2-icon {
            width: 3.5rem;
            height: auto;
            opacity: 0.7;
          }
        }
        .body {
          border: 1px solid map-get($colors, 'tabBorderHoverColor');
          padding: 1rem;
          border-radius: 0 0 1rem 1rem;
          
          .valuation-rate-wrapper {
            background: white;
            border-radius: 1rem;
            padding: 1rem;
            .title {
              font-size: 1.5rem;
              font-weight: bold;
            }
            .progress-legend {
              display: flex;
              margin: 1.3rem 0;
              font-size: 1.3rem;
              margin-left: 15%;
              .pastille {
                margin: 0.3rem;
                width: 1.3rem;
                height: 1.3rem;
                background-color: map-get($colors, 'primaryHoverBackground');
                border-radius: 50%;
              }
            }
            .progress-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 1.3rem;
              .progress {
                width: 70%;
                height: 2rem;
                border-radius: 0.5rem;
              }
            }
            .previous-year-progress-bar {
              margin: 2rem 0 1rem 0;
              
              .progress-bar {
                background-color: map-get($colors, 'gray500');
              }
              .percent-wrapper {
                text-align: right;
                width: 40%;
              }
              .year-wrapper {
                text-align: left;
                width: 20%;
              }
            }
            .current-year-progress-bar {
              font-weight: bold;
              .progress-bar {
                background-color: map-get($colors, 'tableHead');
              }
              .bg-primaryHoverBackground {
                background-color: map-get($colors, 'primaryHoverBackground');
              }

              .percent-wrapper {
                text-align: right;
                width: 40%;
              }
              .year-wrapper {
                text-align: left;
                width: 20%;
              }
            }
          }
        }
      }
      .carousel-wrapper {
        padding: 2.6rem;
        background-color: map-get($colors, 'orange2');
        border-radius: 1rem;
        position: relative;
        overflow: hidden;
        .carousel-font {
          font-size: 1.2rem;
        }
        .background-suez-4-you {
          transform: rotate(-15deg);
          position: absolute;
          top: -60%;
          left: -40%;
          width: 250%;
          height: auto;
          z-index: 0;
        }
        h2 {
          font-size: 1.5rem;
        }
        .dashboard__button {
          background-color: transparent;
          width: 100% !important;
          &::before {
            content: unset;
          }
          &::after {
            content: unset;
          }
        }
        .carousel-control-next {
          width: 5%;
          background-image: none;
          opacity: 0.5;
          .carousel-control-next-icon {
            background-image: url(../../icons/arrow-right-1.svg);
          }
        }
        .carousel-control-prev {
          width: 5%;
          background-image: none;
          opacity: 0.5;
          .carousel-control-prev-icon {
            background-image: url(../../icons/arrow-left.svg);
          }
        }

        .carousel-caption {
          color: black;
        }
        .caption-wrapper {
          right: 30%;
          left: 30%;
        }
        img {
          margin-left: auto;
          margin-right: auto;
        }

        button:after,
        .button__save:after {
          background-color: map-get($colors, 'orange2');
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .Dashboard {
    th {
      &:last-child div {
        display: none;
      }
    }
    .fs-24 {
      font-size: 1.25rem;
    }
    .myDemandsManagement__creationButton {
      width: 19rem;
    }

    .dashboard-demands,
    .dashboard-documents {
      thead {
        th:last-child {
          position: sticky;
          right: 0;
          z-index: 2;
          background-color: map-get($colors, 'gray2');
        }
      }
      tbody {
        tr:nth-child(odd) {
          td:last-child {
            background-color: white;
          }
        }
        tr:nth-child(even) {
          td:last-child {
            background-color: map-get($colors, 'tableSeparatorColor');
          }
        }
        td {
          white-space: nowrap;
          &:last-child {
            position: sticky;
            right: 0;
            z-index: 2;
          }
        }
      }
    }

    .dashboard-card {
      .dashboard-card-header {
        flex-wrap: unset;
        .buttons {
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .Dashboard {
    .dashboard-demands,
    .dashboard-documents {
      table {
        td {
          font-size: 1.1rem !important;
        }
      }
    }

    &__body__right-bloc .carousel-wrapper .background-suez-4-you {
      transform: rotate(3deg);
      position: absolute;
      top: -130%;
      left: -45%;
      width: 250%;
      height: auto;
      z-index: 0;
    }
  }
}
