.legends-section {
    width: 100%;
    padding: 1.2em 2.5em;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-grow: 1;
  
    @include respond-down-to-media-breakpoint(small) {
      display: block;
    }
  
    .pretty {
      display: block;
      margin: .75rem .75rem .75rem 1rem;
    }

    &__elements {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-right: 1rem;
      align-content: flex-start;
    }
    
    &__item {
      display: flex;
      align-items: center;
    }
    
    &__box {
      width: 12px;
      height: 12px;
      margin: 0.5rem;
    }
    
    &__text {
      font-size: 1.2rem;
    }
  
  
    &__right#legends-section__right {
      width: 40%;
      max-width: 23rem; // To enhance after.
  
      @include respond-down-to-media-breakpoint(small) {
        display: block;
        margin: 2rem auto 3rem;
        width: 100%;
      }
  
      p {
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.057rem;
        line-height: 1.8rem;
      }
  
      input.sz-form-group__input {
        padding-left: 1rem !important;
        padding-right: 2.5rem !important;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    &__checkbox {
      margin-left: 0;
      margin-top: 1em;
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .checkbox__label {      
  
        @include respond-down-to-media-breakpoint(small) {
          display: block;
          margin: 0 0 0 2rem;
        }
      }
  
      .pretty {
        margin-left: 0;
      }
    }
  
    &__checkbox {
      height: 1.8rem;
      min-width: 13.1rem;
      color: #030f40;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.8rem;
    }
  
    &__select {
      width: 100%;
      color: map-get($colors, 'blue1');
      font-family: $font-primary;
      font-size: 1.6rem;
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'blue3');
      }
  
      &:hover {
        // sds overrides
        .css-vs1oli-control {
          border-color: map-get($colors, 'action');
        }
      }
    }
  }