.link {
  &--button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.1rem 2.4rem;
    margin: 1rem;
    border: 0.1rem solid transparent;
    background: $backgroundButtonGradient;
    font-size: 1.4rem;
    white-space: nowrap;

    &:hover {
      border-color: map-get($colors, 'action');
    }
  }
}
