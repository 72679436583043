/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  word-spacing: 0.1rem;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/**
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
}

body {
  // with that technique, 1rem = 10px (responsive++, and simplier font' size calculations)
  font-size: 1.6rem;
  color: map-get($colors, 'primary');
  font-family: $font-primary !important; // to prevent SDS overriding
}
