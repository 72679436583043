.billing-detail {
  @include respond-to-between-media-breakpoint(small, large) {
    padding: 0 2.5% 3rem;
  }

  &__title {
    margin-bottom: 1.75rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  &__summary {
    @include materialBox;

    display: flex;
    margin: 0 0 5rem;

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
    }
  }

  &__annexes {
    &-selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3rem 0;
      font-size: 1.6rem;
      font-family: $font-primary;
    }

    &-title {
      font-size: 2rem;
      font-family: $font-primary;

      & svg {
        margin-right: 2rem;
      }
    }
  }

  &__bloc-1 {
    width: 34%;
    margin: 0 9.5% 0 0;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
      margin: 0 0 4rem;
    }
  }

  &__bloc-2 {
    flex-grow: 1;
    font-size: 1.6rem;
    font-family: $font-primary;

    & ul {
      list-style: none;
      padding-left: 0;
    }

    & li {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0;
      border-bottom: 0.2rem solid map-get($colors, 'blue4');

      & div:nth-child(2) {
        font-family: $font-primary-medium;
        text-align: right;
      }
    }
  }

  &__id {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-family: $font-primary;
  }

  &__period {
    font-size: 1.6rem;

    & > div:nth-child(2) {
      font-family: $font-primary;
    }
  }

  &__amount {
    margin: 3.4rem 0;
  }

  &__toPaid {
    padding: 0.8rem;
    border-radius: 0.2rem;
    background-color: map-get($colors, 'mainBackground');
    font-size: 1.2rem;

    // Workaround to wrap boxes nicely (flex-wrap).
    margin: 1rem 1rem 1rem 0;

    @include respond-down-to-media-breakpoint(large) {
      margin-right: 10rem;
    }
  }

  &__paidDownload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .button {
      margin: 1rem 0 1rem auto;
    }
  }

  &__modal {
    &--title {
      font-family: $font-primary-bold;
      margin-block-end: 0.5rem;
    }

    &--section {
      margin-block-end: 1rem;

      .card-header {
        padding-inline: 0;
      }
    }

    &--subsection {
      margin-block-end: 1rem;
    }
    &--subsection:last-child {
      margin-block: 0;
    }

    &--subtitle {
      font-family: $font-primary-medium;
      margin-block-end: 0.25rem;
    }

    &--items {
      margin-inline-start: 1rem;
    }
  }
}
