.tooltip-icon {
  position: relative;
  margin: 0 1rem;
  vertical-align: middle;
  cursor: pointer;

  svg {
    margin-top: -0.9rem;
    fill: map-get($colors, 'gray600');
  }

  &__text {
    display: none;
    position: absolute;
    bottom: 0;
    left: -1.2rem;
    width: 17rem;
    padding: 0.8rem;
    background-color: map-get($colors, 'primary');
    color: map-get($colors, 'white');
    font-size: 1.6rem;
    line-height: 1.8rem;
    border-radius: 0.4rem;
    transform: translate(-33%, -3.5rem);
    z-index: 1;
    white-space: pre-line;

    &::after {
      content: '';
      position: absolute;
      left: 42%;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 1.1rem solid transparent;
      border-right: 1.1rem solid transparent;
      border-top: 1.1rem solid map-get($colors, 'primary');
      clear: both;
    }
  }

  &:hover &__text {
    display: block;
  }
}
