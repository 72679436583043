.custom-file-upload {
  display: flex;
  align-items: center;
  gap: 2px;

  .file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($colors, 'borderFrameColor');
    padding: 1rem;
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 20rem;
    margin: 0;
    position: relative;
    transition: all 0.3s;
    z-index: 1;
    font-family: $font-primary-medium;
    font-size: 1.5rem;
    color: black;

    &.disabled-true {
      border: 1px solid map-get($colors, 'gray700') !important;
      &:hover {
        cursor: not-allowed;
      }
      &::before {
        background: map-get($colors, 'gray') !important;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: map-get($colors, 'tabBorderHoverColor');
      transition: all 0.3s ease;
      z-index: -1;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }

  .file-button {
    display: flex;
    align-items: center;
    overflow: hidden; /* Ensure content does not exceed the container */
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-button span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .deselect-button {
    background: none;
    outline: none;
    border: 1px solid map-get($colors, 'borderFrameColor');
    padding: 1rem;
    svg {
      width: 1.5rem;
      height: auto;
    }
    position: relative;
    transition: all 0.3s;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: map-get($colors, 'tabBorderHoverColor');
      transition: all 0.3s ease;
      z-index: -1;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}
