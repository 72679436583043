.cookies-banner {
  &__modal {
    z-index: 999;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
  }
  &__container {
    display: flex;
    background-color: map-get($colors, 'white');
    width: 100%;
    flex-direction: column-reverse;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__content-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__button {
    width: 19rem;
    margin: 1rem 1.5rem;
  }

  &__content-top {
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
    margin: 1rem 2.5rem 0 1.5rem;
  }

  &__infos {
    display: flex;
    font-size: 1.5rem;
    border-bottom: 1px solid map-get($colors, 'black');
    flex-direction: column;
  }
  &__contentText {
    margin: 1rem 2.5rem 0 1.5rem;

    a {
      color: map-get($colors, 'blueElectric');
    }
  }
  &__closeButton-container {
    display: flex;
    justify-content: flex-end;
  }
  &__closeButton {
    display: flex;
    background-color: map-get($colors, 'white');
    border-color: map-get($colors, 'blue5');
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }
  &__close-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: map-get($colors, 'blue5');
  }
}
