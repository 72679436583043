.table-pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  border-top: 0.1rem solid map-get($colors, 'gray0');
  font-size: 1.4rem;
  line-height: 1.8rem;

  &__top-part {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div {
    & button {
      width: 3.2rem;
      height: 3.2rem;
      margin: 0 0.2rem;
      padding: 7px 0 8px;
      border: 0.1rem solid map-get($colors, 'paginationBorder');
      border-radius: 50%;
      background-color: inherit;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1rem;
      outline: 0;

      &:enabled:hover {
        border-color: map-get($colors, 'paginationBorderHover');
        background-color: transparent;
      }

      &:disabled {
        border-color: map-get($colors, 'paginationDisabledBorder');
        color: map-get($colors, 'primary');
        cursor: not-allowed;
      }
    }
  }

  &__count {
    margin: 0 1rem;
    font-size: 1.8rem;
  }

  &__line-per-page {
    margin-top: 1.4rem;
  }

  &__select {
    padding: 1rem;
  }
}
