.billing {
  display: flex;
  flex-wrap: wrap;

  &__main_title {
    padding-bottom: .5rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary-bold;
    font-size: 3.2rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin-top: 2.5rem;
  }

  &__searchBar {
    margin-top: 3.2rem; 
    
    .searchBar_datePicker {
      margin-left: 0;
    }
  }

  &_marginFooter {
    margin: 0 0 5.8rem;
  }

  &_loadMoreButton {
    margin: 2rem auto 0 auto ;
    justify-content: center;
  }

  &_yearCollapse {
    margin-top: -0.8rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    text-align: center;
  }

  &_yearCheckbox {
    position: absolute;
    z-index: 1;
    margin-top: 0.2rem;
  }

  &_iconSelectAll {
    height: 2.4rem;
    width: 2.4rem;
    align-self: center;
  }

  &_accordionToggle {
    display: block;
    width: 100%;
    padding-right: 0;
  }

  &_CollapseContainer {
    
    hr {
      margin-right: 1rem;
      width: 100%;

      @include respond-down-to-media-breakpoint(small) {
        margin-left: 3rem;
      }
    }
  }

  &__switchButton {
    height: 3.6rem !important;
    width: 10.8rem !important;
    margin-right: 0.5rem !important;
    font-family: $font-primary-bold !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.05rem;
    outline: 0 !important;

    span {
      font-family: $font-primary-bold !important;
    }

    &_width {
      width: 12.8rem !important;
    }
  }

  &_switchButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }

  &_switchTab {
    display: flex;

    .button {
      padding: 0;
    }
  }

  &__nbSelected {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
  }

  &_downloadBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    margin: 2rem 0 0;

    &-button {
      display: flex;
      justify-content: space-around;
      align-items: center;

      &-icon {
        height: 2.5rem;
        width: 2.4rem;
        margin-right: 1rem;
        color: map-get($colors, 'primary');
      }
    }
  }

  &_marginButton {
    margin-right: 1rem;
  }

  &_cardPeriod {
    margin-top: 1.9rem;
    text-transform: lowercase;
  }

  &_leading-small {
    line-height: 1.4rem;
  }

  &_leading-medium {
    line-height: 2.2rem;
  }

  &_mt-s {
    margin-top: 2.5rem;
  }

  &_mt-xs {
    margin-top: 0.4rem;
  }

  &_accordionTitle {
    display: flex;
  }

  &_cards {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, 31.1rem);
    width: 100%;
    margin-top: 2.5rem;
  }

  &_card {
    display: flex;
    flex-direction: column;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    padding: 1.6rem 1.5rem;
    background-color: map-get($colors, 'white');
    color: map-get($colors, 'blue1');
    box-shadow: 0.3rem 0.3rem 1.2rem -0.4rem rgba(17, 95, 251, 0.1);
  }

  &_cardTextNumber {
    margin-left: 1rem;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    text-decoration: none;
  }

  &_cardTitle {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &_cardPrice {
    margin-top: 2.5rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
  }

  &_CardStatus {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 3.2rem;
    width: 7.3rem;
    margin-left: auto;
    background-color: map-get($colors, 'mainBackground');
    color: map-get($colors, 'primary');
    font-size: 1.2rem;
    letter-spacing: 0.05rem;

    &--inactive {
      background-color: inherit;
    }
  }

  &_errorAlert {
    margin: 2rem 0;
    font-size: 1.8rem;
  }

  &_infoMessage {
    position: relative;
    bottom: 0;
    margin-top: 3rem;
  }

  &_downloadBarButtonIcon-2 {
    height: 4.8rem !important;
    width: 4.8rem !important;
  }
  &_separator {
    border-color: white !important;
  }

  &_cardButtonContainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 1.9rem;
  }

  &_iconRightContainer {
    margin-top: -1.5rem;
    padding: 1.6rem 1rem;
    border: 0.1rem solid map-get($colors, 'blue4');
    border-radius: 0.2rem;
    background-color: map-get($colors, 'white');

    svg {
      height: 0.8rem;
    }
  }

  &_CollapseContainer {
    display: flex;
    border: none;
  }

  &_accordionToggle {
    border-width: 0;
    background-color: map-get($colors, 'mainBackground');
    outline: 0 !important;
  }

  &_switchDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.6rem;
    width: 8.6rem;
    border-radius: 0.2rem;
    background-color: map-get($colors, 'blue4');
    outline: none;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.4rem;
      width: 2.4rem;
      margin: 0 0.5rem;
      background: map-get($colors, 'primaryDisabled');
      color: map-get($colors, 'primary');
      border: none;

      &:focus {
        outline: 0;
      }

      &:hover {
        background: map-get($colors, 'primaryHoverBackground');
      }

      &--active {
        background: map-get($colors, 'primaryButtonColor');
      }
    }

    &_icon {
      height: 1.6rem;
      width: 1.6rem;

      path {
        fill: map-get($colors, 'primary');
      }
    }

    &__button--active &_icon {
      path {        
        fill: map-get($colors, 'primary');
      }
    }

    &_avoir {
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  &__flexDisplay {
    display: flex;
    align-items: center;
  }

  &__table {
    margin-top: 1.5rem;

    // Hierarchy like that to override default styles.
    .table {

      > table {

        > thead {

          th {
            padding-left: 10px;

            input {
              background: #fff !important;
            }

            &.selection {
              min-width: 30px;
            }

            &.periode {
              min-width: 220px;
              width: 220px;
            }    

            &.etablissement {
              min-width: 250px;
              width: 250px;
            }        

            &.numFacture {
              min-width: 140px;
            }

            &.adresse {
              min-width: 230px;

              .table__sort {
                display: none;
              }
            }

            &.montantTotalHT {
              min-width: 150px;
            }

            &.statut {

              .searchBar_select {
                max-width: 150px;
              }
            }

            &.contenantLibelle {
              min-width: 145px;
            }

            &.adresseSiteClientNom {
              min-width: 290px;
            }

            &.numerateursyntton {
              min-width: 250px;
            }

            &.familleDechetLibelle {
              min-width: 194px;
            }

            &.numerateursyntpas {
              min-width: 170px;
            }

            &.entrepriseFactureeNom {
              min-width: 220px;
            }
          }
        }

        tbody {

          td {

            &:first-child {
              padding-left: 10px;
            }

            .billing__intervalCell {
              text-align: center;

              > div {

                &:first-child {
                  margin-right: .5rem;
                }
                display: inline-block;
              }
            }
          }
        }
      }
    }

    
  }

  &__downloadCredit {
    display: flex;
    justify-content: center;
  }

  &__statusCell {
    display: flex;
    align-items: center;

    span {
      width: 11rem;
    }
  }
}
