.szcheckbox {
  margin-left: 1rem;
}

.sz-form-group .input-group .sz-form-group__input {
  height: 4.8rem !important;
  padding-left: 4.5rem !important;
  background-color: map-get($colors, 'grey0') !important;
  color: map-get($colors, 'primary');
  font-size: 1.6rem;
  font-family: $font-primary-medium;
}

.sz-form-group__input-icon .sz-icon-line.search {
  font-size: 2.4rem;
  fill: map-get($colors, 'blue2');
}

.sz-select-overrides {
  z-index: 6;
}

.sz-accordion .sz-collapse__header {
  padding-left: 0 !important;

  &::after {
    display: none;
  }

  & .sz-btn.sz-btn-tertiary {
    color: map-get($colors, 'primary');
  }

  & .sz-icon-line {
    font-size: 2.4rem;
  }
}

.accordion {
  hr {
    margin-top: 0.8rem;
    margin-right: 4.6rem;
    border-top: 0.1rem solid map-get($colors, 'blue3');
  }

  &__wrapper {
    margin-top: 3.4rem;

    > .pretty {
      top: 14px;
    }
  }
}

.Sz-pagination {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.button-icon {
  height: 4.8rem !important;
  width: 21.5rem;
  margin-right: 2rem;
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.8rem;
  text-align: center;

  > span {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    width: 70%;
  }

  &--wide .d-block {
    width: 100%;
  }
}

.sz-datepicker {
  height: 100%;
  font-size: 1.6rem;
  border: 0.1rem solid map-get($colors, 'blue3');
  border-radius: 0.2rem;

  &:hover {
    border-color: map-get($colors, 'linkHover');
  }

  &.row {
    align-items: center;
  }

  .react-date-picker {
    height: 100%;
    width: auto;
  }

  .react-date-picker__button {
    .sz-icon-line {
      font-size: 2.4rem;
    }

    .arrow-down-1 {
      display: none;
    }
  }

  .sz-datepicker__start::before,
  .sz-datepicker__end::before {
    display: none;
  }

  .sz-datepicker__start::after {
    content: '\2192';
    padding-top: 1.2rem;
  }

  .sz-datepicker__end {
    margin-left: 2rem;
  }

  .react-date-picker__wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0;
    border-color: transparent;

    .react-date-picker__inputGroup {
      margin-left: -0.6rem;
    }
  }

  .react-date-picker input {
    color: map-get($colors, 'primary');
  }

  .react-date-picker__inputGroup {
    margin-top: 0.5rem;
  }

  .react-calendar {
    font-size: 1.2rem;
  }

  .react-date-picker input,
  .react-date-picker__inputGroup__leadingZero,
  .react-date-picker__inputGroup__divider {
    font-weight: normal;
  }

  .react-date-picker__calendar.react-date-picker__calendar--open {
    z-index: 7;
  }

}
