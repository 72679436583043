$mt-small: 0.6rem;

.billing-synthesis {
  padding-bottom: 7rem;

  &__title {
    margin-bottom: 1.75rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  &__flex {
    display: flex;
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-grow: 1;
    margin-right: 2.4rem;
  }
  &__infoSection {
    display: flex;
    flex: 1;
    align-items: center;
  }
  &__year-range {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
  &__year-range-label {
    background-color: white;
    font-size: 1.6rem;
    line-height: 3.2rem;
    margin-right: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__first-row {
    min-height: 9.4rem;
    display: flex;
    & > .billing-synthesis__right {
      .billing-synthesis__export-button {
        margin-left: auto;
        box-sizing: border-box;
        height: 4.8rem;
        width: 16.6rem;

        &:hover {
          border-color: map-get($colors, 'action');
        }

        &--active {
          border-color: map-get($colors, 'blue6');
          background-color: map-get($colors, 'blue4');
        }
      }
    }
  }

  &__info {
    font-size: 1.3rem;
    color: map-get($colors, 'gray1');
    width: 100%;
  }

  &__chart-title {
    font-size: 2rem;
  }

  &__switch-tab#billing-synthesis__switch-tab {
    display: flex;
    margin-bottom: 1rem;

    .button {
      padding: 0;

      &__switch-button {
        height: 3.6rem;
        width: 8rem;
        margin-right: 0.5rem;
        font-family: $font-primary-bold;
        font-size: 1.2rem;
        letter-spacing: 0.05rem;
        outline: 0;
      }
    }
  }

  &__main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__stacked-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 37.4rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
      0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
    margin-bottom: 0.5rem;
  }

  &__bottomSection {
    display: flex;
    height: 4.6rem;
    justify-content: flex-end;
  }

  &__checkbox {
    height: 2.8rem;
    min-width: 13.1rem;
    color: map-get($colors, 'blue7');
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-right: 2rem;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-items: center;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .checkbox__label {
      @include respond-down-to-media-breakpoint(small) {
        display: block;
        margin: 0 0 0 2rem;
      }
    }
    .pretty {
      margin-left: 0;
    }
  }

  #billing-synthesis__detail-button {
    .billing-synthesis__detail-button {
      margin-left: auto;
      box-sizing: border-box;
      height: 4.8rem;
      width: 16.6rem;
      background: map-get($colors, 'white');
    }
  }

  &__right_evolution {
    width: 50%;
  }

  &__empty-space {
    height: 3.6rem;
    margin-bottom: 1rem;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-grow: 1;
    & > .legends-section {
        margin-bottom: 5.7rem;
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .billing-synthesis {
    &__year-range {
      justify-content: center;
      margin-bottom: 1rem;
    }
    &__flex {
      flex-direction: column;
    }
    &__right_evolution,
    &__left,
    &__right {
      width: 100%;
    }
    &__right_evolution,
    &__right {
      margin-top: $mt-small;
    }
    &__stacked-chart {
      min-height: 22rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .billing-synthesis {
    &__year-range {
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    &__year-range-label {
      margin-right: 0rem;
      margin-bottom: 1rem;
    }
    &__title {
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }

    &__info {
      text-align: justify;
      margin-bottom: 1.8rem;
    }

    &__first-row {
      flex-direction: column;
      align-items: center;

      & > .billing-synthesis__right {
        margin-bottom: 2rem;
        .billing-synthesis__export-button {
          margin: auto;
        }
      }
    }

    &__switch-tab#billing-synthesis__switch-tab {
      .button {
        padding: 0;
        width: 100%;
        &__switch-button {
          width: 0;
          flex-grow: 1;
          height: 5rem;
          font-size: 1.8rem;
          font-family: $font-primary-bold;
        }
      }
    }

    &__empty-space {
      display: none;
    }

    &__stacked-chart {
      min-height: 20rem;
    }
  }
}

@include respond-down-to-media-breakpoint(small) {
  .billing-synthesis {
    &__title {
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }

    &__info {
      text-align: justify;
      margin-bottom: 1.8rem;
    }

    &__first-row {
      display: block;

      & > .billing-synthesis__right {
        margin-bottom: 2rem;
        .billing-synthesis__export-button {
          margin: auto;
        }
      }
    }

    &__switch-tab#billing-synthesis__switch-tab {
      .button {
        padding: 0;
        width: 100%;
        &__switch-button {
          width: 0;
          flex-grow: 1;
          height: 5rem;
          font-size: 1.8rem;
          font-family: $font-primary-bold;
        }
      }
    }

    &__empty-space {
      display: none;
    }

    &__stacked-chart {
      min-height: 20rem;
      padding-top: 1.6rem;
    }
  }
}
