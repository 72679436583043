.scroll-list {
  &__container {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    overflow: auto;
    border-width: thin;
    border-color: map-get($colors, 'primary');
    border-style: solid;

    li {
      list-style-type: none;
      padding: 1rem 0;
      color: map-get($colors, 'primary');
      font-family: $font-primary;
      font-size: 1.6rem;
      letter-spacing: 0;
      line-height: 1.8rem;
      padding-left: 2rem;
      display: flex;
      align-items: center;
    }
    li.odd {
      background: map-get($colors, 'white');
    }
    .label {
      flex: 1;
      word-break: break-word;
    }
  }
}
