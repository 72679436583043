.modify-user-email {
  &__input {
    flex-grow: 0 !important;
    margin-right: 1rem;
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-top: 1.2rem;
  }
  &__cgu-block {
    margin-top: 4rem;
  }
  &__footer {
    display: flex;
    flex-direction: column;
  }
  .form {
    box-shadow: none;
  }
}

.modify-email-info {
  color: map-get($colors, 'blue5');
  font-size: 1.2rem;
  font-family: $font-primary;
  line-height: 1.2rem;
  text-align: center;
  word-spacing: 0.1rem;
  margin-bottom: 2rem;

  p {
    margin-bottom: 0rem;
  }
}
