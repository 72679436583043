.create-user-profile {
  display: flex;
  flex-direction: column;

  .pretty {
    margin-right: 2rem;
  }

  @include respond-up-to-media-breakpoint(large) {
    min-width: 993px;
  }

  @include respond-down-to-media-breakpoint(large) {
    width: 90%;
    margin: auto;
  }

  @include respond-down-to-media-breakpoint(small) {
    width: 100%;
  }

  &_title_page {
    margin-bottom: 1.9rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.6rem;
  }
  &_infos {
    margin: 2rem 0;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    button {
      svg {
        margin-right: 1.5rem;
      }
    }
  }

  &__save {
    margin-right: 2rem;
  }
}

.form-label-create-user-profile {
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  font-weight: 500;
  display: inline-flex;
  justify-content: space-around;
  letter-spacing: 0;
  line-height: 1.7rem;
  width: 100%;
}

.mandatory_indicator {
  text-align: right;
  align-self: flex-end;
  display: inline-block;
  color: map-get($colors, 'error');
  width: 100%;
}

.mandatory_star {
  color: map-get($colors, 'error');
}

.form-label-title-create-user-profile {
  align-self: flex-start;
  width: inherit;
}

.radio-button-user-type {
  margin-top: 1rem;
  display: flex;
  font-family: $font-primary-medium;
  color: map-get($colors, 'primary');
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.7rem;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 2rem;

    .checkbox__label {
      margin-bottom: 0;
    }
  }
}

.block-id-upn {
  display: flex;
  justify-content: space-between;
}

.form-input-id-upn {
  width: 75%;
  margin-right: 5%;
}

.button-id-upn {
  height: 5.8rem !important;
  width: 18rem;
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.8rem;
  text-align: center;
}

.button-save {
  margin-right: 2rem;
}

@media screen and (max-width: $mobileWidth) {
  .radio-button-user-type {
    flex-direction: column;
    padding-left: 0.8rem;
  }

  .block-id-upn {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .form-input-id-upn {
      width: 100%;
      margin-right: 0;
    }

    .btn {
      width: 100%;
    }
  }

  .create-user-profile {
    &__actions {
      flex-direction: column;

      .form__submit:first-of-type {
        margin-bottom: 1.5rem;
      }
    }

    .radio-button-user-type {
      .create-user-profile_empty-space {
        display: none;
      }
    }
  }
}
