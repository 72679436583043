.table {
  position: relative;
  font-family: $font-primary-medium;
  overflow-x: auto;
  table {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      width: 100%;
      height: 1px;
      background-color: map-get($colors, "paginationDisabledBorder");
      display: block;
    }
  }

  .pretty {
    margin-right: 0;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  .DateRangePickerInput_arrow {
    right: 0;
    font-size: 1em;
  }

  .user-management__ButtonCell {
    justify-content: center;
  }

  & > table {
    width: 100%;

    & > .bodyLoadOn:not(.loading) {
      opacity: 0.3;
      position: relative;
    }
    
    & > .bodyLoadOff:not(.loading) {
      opacity: 1;
    }

    & > thead {
      background-color: map-get($colors, "tabBorderHoverColor");

      th {
        padding: 0;

        &:first-child {
          min-width: unset;
        }

        &.familleOrganisation {
          .searchBar_select {
            width: auto;
          }
        }
      }

      .table__header-cell {
        position: relative;
        min-height: 90px;
        color: map-get($colors, "primary");

        &::after {
          content: "";
          clear: both;
          display: table;
        }
      }
      
      .table__header-cell-kpi {
        position: relative;
        min-height: 30px;
        color: map-get($colors, "primary");
        text-align: center;
        @media screen and (max-width: 1190px) {
          min-height: 5vh;
        }
      }
    }

    & > thead th {
      position: relative;
      padding: 16px 0 0;
      font-family: $font-primary;
      font-size: 1.5rem;
      color: map-get($colors, "gray1");
      line-height: 1.4rem;
      vertical-align: top;
    }

    & > tbody td {
      height: 5rem;
      vertical-align: middle;
      padding: 0;
      color: map-get($colors, "primary");
      font-size: 1.4rem;
      line-height: 1.8rem;
      border-bottom: 0.1rem solid map-get($colors, "tableBorder");
      word-break: break-word;

      .billing__flexDisplay {
        width: 100%;

        .billing__flexDisplay {
          justify-content: flex-end;
        }
      }

      &:not(:first-child) {
        padding: 5px 10px;
      }

      &:nth-last-child(2) {
        vertical-align: middle;

        & > div.user-management__roleCell {
          width: 1.5em;
          
          p {
            margin-bottom: 0px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    & > tbody td.firstRenderLoad {
      background-color: map-get($colors, "mainBackground");
    }

    & > tbody tr:nth-child(odd) {
      background-color: map-get($colors, "white");
    }

    & > tbody tr:nth-child(even) {
      background-color: map-get($colors, "datePickerSelectedBorder");
    }

    & > tbody {
      .table__body-row-kpi td:not(:nth-child(-n+2)){
        text-align: center;
      }
    }

    & > thead tr:first-child th:first-child .table__header-cell .table__separation {
      display: none;
    }

    & > thead tr:first-child th:first-child .table__header-cell .table__sort svg {
      display: none;
    }
    
    & > thead tr:first-child th:first-child .table__header-cell-kpi .table__separation,
    & > thead tr:first-child th:first-child .table__header-cell-kpi .table__sort svg {
      display: none;
    }
  }

  &__minHeight {
    min-height: 45rem;
  }

  &__sort {
    position: relative;
    left: 5px;

    &-icon {
      height: 1rem;

      & path {
        fill: map-get($colors, "tablesortEnabled");
      }

      &--inactive path {
        fill: map-get($colors, "primary");
      }
    }
  }

  &__filter {
    background: map-get($colors, "tabBorderHoverColor");
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 0 6px 10px;
    height: 59px;

    input {
      width: 99%;
      height: 35px;
    }

    &-button {
      border: none;
      background-color: inherit;
      outline: 0 !important;
    }

    .searchBar_select {
      font-size: 1em;

      [class$="-indicatorContainer"] {
        padding: 2px;
      }
    }

    .DateRangePicker {
      line-height: 1.5;
      font-size: 1em;
      border: none;

      .DateInput {
        width: 80px;
      }

      .DateRangePickerInput_arrow svg {
        width: 16px;
        height: 16px;
      }

      input.DateInput_input {
        font-size: 1em;
        padding: 0 3px;
      }
    }

    &-input {
      height: 23px;

      &--hidden {
        display: none;
      }
    }

    &-icon {
      height: 1.3rem;
      cursor: pointer;
    }

    .searchBar_select {
      width: auto;
      max-width: 98%;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    // sds overrides
    [class$="singleValue"] {
      line-height: 20px;
    }
  }

  &__separation {
    position: absolute;
    top: -8px;
    right: 0;
    background-color: map-get($colors, "tableSeparatorColor");
    display: inline-block;
    width: 0.2rem;
    height: 3rem;
  }
  &__disableRow {
    color: map-get($colors, "blue4");
  }

  &__caption {
    background-color: white;
    text-align: center;
    padding: 1.5rem;
    color: map-get($colors, "primary");
    font-size: 1.4rem;
    line-height: 1.8rem;
    border-bottom: 0.1rem solid map-get($colors, "tableBorder");
  }
}

.kpi-table {
  .table > table {
    width: 100%;
    min-width: 800px;
  }

  .table__header-cell-kpi .loading {
    left: 0rem;
    top: -3rem;
  }
}
