.screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__fixed {
    position: relative;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 7;
  }

  .sticky {
    position: relative;
    top: 0;
    max-width: $desktopWidth;
    z-index: 999;


  }

  &__scroll {
    max-width: 100%;
    margin-bottom: auto;

    &--perimeterFilterBig {
      transition: all 300ms ease;
    }
  }
}

svg {
  pointer-events: none;
}
