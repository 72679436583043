.alert {
  &__danger {
    font-size: 1.8rem;
    height: 4.5rem;
  }
  &__bottomGap {
    margin-bottom: 2rem;
  }
  &__topGap {
    margin-top: 2rem;
  }
}
