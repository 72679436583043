.amount {
  font-family: $font-primary-bold;
  font-size: 3.2rem;
  line-height: 4rem;

  > sup {
    font-size: 1.2rem;
    line-height: 1.2rem;
    letter-spacing: 0.05rem;
    vertical-align: middle;
  }
}
