.sessionExpiration {
  &__content {
    width: 100%;
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__actions {
    margin-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}