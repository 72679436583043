.tonnage-filter-modale {
  .title {
    font-size: 2rem;
    font-family: $font-primary-bold;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-top: 5rem;

    .filters {
      display: flex;
      .month-select,
      .year-select {
        width: 8vw;
        margin-right: 1rem;
      }
    }

    .export {
      margin-top: 5rem;
    }
  }
}
