.billing-annex {  
  &__header {
    display: flex;
    height: 6.4rem;

    .pretty {
      margin-right: 10px;
    }

    &-content {
      display: flex;
      align-items: center;

      & .checkbox {
        margin-right: 1.8rem;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  &__subheader {
    display: flex;
    height: 4.4rem;

    &-content {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-family: $font-primary;
    }

    &-date {
      font-weight: 600;
      margin-right: 0.7rem;
    }
  }

  &__content {
    // OVERRIDES SDS here (because it's specific)
    .sz-accordion .sz-collapse__header {
      margin: 0.4rem 0.4rem 0 0.4rem;
      border: 0.1rem solid #dcdee4;
      border-bottom: transparent;
      box-shadow: none;
    }

    &-body {
      padding: 0.4rem;
      margin: 0 0.4rem 0.4rem 0.4rem;
      border: 0.1rem solid #dcdee4;
      border-top: transparent;
    }
  }

  &__collapsed {
    margin: 0 1.8rem 0 0.4rem;
    border-left: 0.4rem solid transparent;
    border-radius: 0.4rem;

    &--active {
      border-left-color: map-get($colors, 'blue2');
    }
  }

  &__directory-number {
    margin-bottom: 0.4rem;
    font-size: 1.6rem;
    font-family: $font-primary-bold;
  }

  &__folderDescription {
    color: map-get($colors, 'primary');
    margin-top: 0.3rem;
    font-size: 1.2rem;
  }

  &__compagnyAddress {
    color: map-get($colors, 'gray600');
  }

  &__table {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      height: 5.3rem;
      padding: 0 2rem;
      font-size: 1.4rem;
      color: map-get($colors, 'blue1');

      div:not[class^="tooltip-image__text"] {
        width: 36rem;
      }

      > div:first-child {
        margin-right: 7rem;

        @include respond-down-to-media-breakpoint(small) {
          margin-right: 2rem;
        }
      }

      > div:last-child {
        flex-grow: 1;
        text-align: right;
      }

      @include respond-down-to-media-breakpoint(small) {
        height: auto;
        padding: 1rem;
      }
    }

    li:nth-child(1n) {
      background-color: map-get($colors, 'gray100');
    }

    li:nth-child(2n) {
      background-color: map-get($colors, 'white');
    }
  }

  &__dl-button {
    border: none;
    background-color: transparent;
    outline: none;

    svg {
      margin-left: 2rem;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      color: map-get($colors, 'linkHover');

      svg {
        fill: map-get($colors, 'linkHover');
      }
    }
  }
}
