$bgColor: map-get($colors, 'white');
$bgFocusColor: map-get($colors, 'white');
$iconColor: map-get($colors, 'blue2');

.input {
  display: flex;
  align-items: center;
  height: 4.8rem;
  border-radius: 0;

  &__focus {
    border: 1px solid map-get($colors, 'tabBorderColor');

    &--on {
      .input__field,
      .input__before,
      .input__after {
        background-color: $bgFocusColor;
      }
    }

    &--off {
      border: 1px solid map-get($colors, 'primaryDisabled');

      .input__field,
      .input__before,
      .input__after {
        background-color: $bgColor;
      }
    }
  }

  &__field {
    flex-grow: 1;
    height: 100%;
    border-color: transparent;
    font-size: 1.6rem;
    outline: none;

    &::placeholder {
      font-family: $font-primary;
      font-size: 1.6rem;
      color: map-get($colors, 'blue1');
    }
  }

  &__disabled {
    cursor: not-allowed;
  }

  &__before {
    width: 4.8rem;

    :first-child {
      color: $iconColor;
      font-size: 2.4rem;
    }
  }

  &__after {
    width: 3rem;
    cursor: pointer;

    :first-child {
      color: $iconColor;
      font-size: 1.6rem;
    }
  }

  &__before,
  &__after {
    display: grid;
    place-items: center;
    height: 100%;
  }
}
