
.table-evolution {
  &__table {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  > .table {

    table {

      > thead {

        th {
          padding-left: 10px;
  
          &.selection {
              min-width: 30px;
          }
  
          &.famillePrestationLibelle {
              min-width: 120px;
          }

          &.adresseSiteClientNom {
            min-width: 250px;
          }

          &.entrepriseFactureeNom {
            min-width: 150px;
          }

          &.contenantLibelle {
            min-width: 125px;
          }

          &.familleDechetLibelle {
            min-width: 194px;
          }

          &.numerateursyntqte, &.numerateursyntfac, &.numerateursyntbrm {
            min-width: 160px;
          }          
        }
  
        .table__header-cell {
            min-height: 100px;
        }
      }
  
      tbody {
  
        td {
  
          &:first-child {
            padding-left: 10px;
          }
        }
      }
    }

    
  }

}