.activity-analyse-evolution {
    &_header {
        min-height: 5.4rem;
    }
    &_header-asterix {
        font-size: 1.6rem;
        color: map-get($colors, "gray1");
    }
    &__chart-title {
        font-size: 2rem;
        margin-bottom: 0;
    }

    &__select {
        border: 0.1rem solid map-get($colors, "linkHover") !important;
    }
    &__smart-select {
        width: 17rem;
    }
    &__search {
        width: inherit !important;
    }
    &__select-arrow {
        margin: 0 !important;
    }

    &__export-button {
        width: 100%;
        border: 0!important;
        border-bottom: 0.1rem solid map-get($colors, "gray500") !important;
        justify-content: left !important;
        padding: 0 1rem !important;
        &:hover {
            border: .05rem solid map-get($colors, "linkHover")!important;
        }
    }

    &__nbSelected span {
        margin: 0 1rem;
    }
}
