.tooltip-image {
  position: relative;
  margin: 0 1rem;
  vertical-align: middle;
  cursor: pointer;

  svg {
    margin-top: -0.9rem;
    fill: map-get($colors, 'gray600');
  }

  &__content {
    visibility: hidden;
    position: absolute;
    width: 15rem;
    padding: 0.8rem;
    background-color: map-get($colors, "primary");
    color: map-get($colors, "white");
    font-size: 1.6rem;
    line-height: 1.8rem;
    border-radius: 0.4rem;
    z-index: 999;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 1.1rem solid transparent;
      border-right: 1.1rem solid transparent;
      border-top: 1.1rem solid map-get($colors, "primary");
      clear: both;
    }
  }

  &__content-image {
    width: auto;
  }

  &__top {
    bottom: 0;
    left: -2.2rem;
    transform: translate(-33%, -3.5rem);

    &::after {
      left: 42%;
      top: 100%;
    }
  }

  &__bottom {
    top: 5%;
    left: -2rem;
    transform: translate(-33%, 3.5rem);

    &::after {
      left: 42%;
      top: -1rem;
      transform: rotate(180deg);
    }
  }

  &__left {
    height: fit-content;
    top: 0;
    left: -1rem;
    bottom: 0;
    transform: translate(-100%);
    margin: auto 0;

    &::after {
      right: -1.6rem;
      bottom: 50%;
      transform: rotate(-90deg);
    }
  }

  &__right {
    height: fit-content;
    top: 0;
    bottom: 0;
    transform: translate(165%);
    margin: auto 0;

    &::after {
      left: -1.6rem;
      bottom: 50%;
      transform: rotate(90deg);
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: center;
    @media screen and (max-width: $mobileWidth) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &__text-image {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-bottom: .6rem;
  }

  &__image {
    font-size: 1.3rem;
    width: 28rem;
    height: auto;

    @media screen and (max-width: $mobileWidth) {
      max-width: 18rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &:hover &__content {
    visibility: initial;
  }
}
