.session-expired {
  margin-block-start: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #030f40;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);

  .form {
    @include respond-down-to-media-breakpoint(small) {
      padding: 2rem;
    }
  }

  &_image {
    width: 30rem;
    margin-block: 2rem;
    fill: map-get($colors, "green");

    @include respond-down-to-media-breakpoint(small) {
      width: 22rem;
    }
  }
  &_title {
    font-family: $font-primary;
    text-align: center;
    font-size: 3.2rem;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-block: 2rem;

    @include respond-down-to-media-breakpoint(small) {
      font-size: 2.4rem;
    }
  }
  &_button {
    margin: 3rem;
  }
  &_recheck-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 3.4rem;
  }

  &_form-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 2.3rem 0;

    @include respond-down-to-media-breakpoint(small) {
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .session-expired {
    .form__submit {
      width: 100%;
    }
  }
}
