.create-organization {
  @media only screen and (max-width: $tabletWidth) {
    display: inline-grid !important;
    .empty_col_padding {
      padding-top: 14px !important;
    }
  }
  position: relative;
  display: flex;
  font-size: 1.6rem;  

  &__left,
  &__right {
    width: 50%;
    padding: 2rem;
  }

  &__left {
    background-color: map-get($colors, 'white');

    > .create-organization__validateButton {
      position: absolute;
      right: 0;
    }

    .smart-input-organization {

      .borderColorLvl--1, &.borderColorLvl--1 {
        border-color: map-get($colors, 'green2') !important;        
      }   

      &__button {

        &:hover {

          path {
            fill: map-get($colors, 'linkHover');
          }
        }
      }
    }    
    .breadcrumb__item {

      .borderColorLvl--1 {
        border-color: map-get($colors, 'green2') !important;
      }

      &:hover {
        color: map-get($colors, 'linkHover');
      }
    }
  }

  &__right {
    margin-top: 6.5rem;
    background-color: map-get($colors, 'mainBackground');
  }

  .form {
    padding: 0rem;
    box-shadow: none;
  }

  &__form {
    padding: 2.4rem 2.4rem 0;
    border: 0.2rem solid transparent;
    border-radius: 0.2rem;
    margin-bottom: 1.3rem;
  }

  &__form-checkbox-group {
    margin-bottom: 0.5rem;

    > input {
      margin: 1rem 0 0 1rem;
    }

    .form-radio:first-of-type {
      display: inline-block;
      min-width: 50%;
    }
  }

  &__groupingSummary {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 0;
    font-size: 1.6rem;
  }

  & .button {
    &:focus {
      outline: 0 !important;
    }
  }

  &__modifyButton {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: map-get($colors, 'blue1');
    font-family: $font-primary-medium;
    font-size: 1.4rem;

    &-label {
      margin-right: 1.6rem;
      margin-bottom: -0.7rem;
    }

    &:hover {
      color: map-get($colors, 'linkHover');
    }

    &:hover &-label {
      svg {
        fill: map-get($colors, 'linkHover');
      }
    }

    &:focus {
      outline: 0 !important;
    }
  }

  &__validateButton {
    position: absolute;
    top: 0;
    right: 0%;
    margin-top: 2rem;
    margin-right: 2rem;
    width: 15rem;

    svg path {
      fill: map-get($colors, 'primary');
    }
  }
  &__mainTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary-bold;

    letter-spacing: 0;
    @media only screen and (min-width: $tabletWidth) {
      font-size: 3.2rem !important;
    }
  }
  .back-home {
    margin: 1rem 0 2.5rem;
  }

  &__table--small {
    height: 100%;

    .name,
    .address {
      font-size: 1.4rem;
      text-align: left;
    }

    .address {
      font-family: $font-primary;
      margin-left: -0.5rem;
    }

    .code {
      font-family: $font-primary;
      font-size: 1.4rem;
    }
  }

  .table {

    > table {

      > thead {

        th {
          padding-left: 10px;

          &.selection {
            min-width: 30px;
          }   
          
          &.col3 {
            min-width: 130px;
          }
        }
      }

      tbody {

        td {
          vertical-align: middle;

          &:first-child {
            padding-left: 10px;
          }
        }
      }
    }
  }

  &__step2 {
    height: 100%;
    margin-top: -6rem;    
  
    .title {
      display: flex;
      margin-bottom: 0.7rem;
      font-size: 2rem;

      svg {
        margin-right: 1.5rem;
      }
    }

    .filter {
      @media only screen and (max-width: $tabletWidth) {
        display: inline-grid !important;
        .empty_col_padding {
          padding-top: 14px !important;
        }
      }
      @include materialBox;
      display: flex;
      justify-content: space-between;

      &__title {
        font-family: $font-primary-bold;
      }

      &__organizations,
      &__institutions {
        @media only screen and (max-width: $tabletWidth) {
          width: 100%;
        }
        width: 48%;
      }
    }

    .smart-select__select {
      border-color: map-get($colors, 'blue4');
    }

    .smart-select__select-label {
      font-size: 1.4rem;
    }

    .emptyMsg {
      margin-top: 4rem;
      padding: 0 1rem;
      font-style: italic;
      text-align: center;
    }
  }

  .selected-and-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4.2rem 0 3.2rem;
  }
  .selected-and-action-hidden {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4.2rem 0 3.2rem;
    visibility: hidden;
  }

  &__selectedElements {
    display: flex;
    margin-bottom: 2.4rem;
    font-size: 2rem;

    svg {
      margin-right: 1.5rem;
    }
  }

  &__multiCollapse {
    margin-bottom: 1.5rem;
  }

  &__attachDirectly {
    width: 100%;
    margin: 0 0 2rem;

    .selected-container {
      margin-top: -0.2rem;
      border: 0.2rem solid;
    }
  }

  &__step2bis {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button {
      width: 33rem;
      background: none;
    }

    .button:first-of-type {
      margin-bottom: 1rem;
      justify-content: flex-start;
      padding-left: 2.25rem;
    }

    .inverseColor {
      color: map-get($colors, 'white');

      svg path {
        fill: map-get($colors, 'white');
      }
    }
  }

  &__stepImport {
    .title {
      font-size: 2rem;
    }

    .infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2.4rem 0;
    }

    .actions {
      display: flex;

      .button:first-of-type {
        margin-right: 1.7rem;
      }

      .button:nth-child(2) {
        width: 12.5rem;
      }
    }
  }

  &__imported {
    width: 100%;

    hr {
      margin: 3rem 0 2rem;
      border-top: 0.1rem solid map-get($colors, 'gray700');
    }

    .title {
      font-size: 2rem;
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 2rem 0 3rem;
    }

    .item {
      margin: 0.5rem 0;
    }
  }
}
.disabled-div {
  pointer-events: none;
  opacity: 0.4;
}
