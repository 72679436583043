.buttonSquareIcon {
  height: 4.8rem !important;
  width: 4.8rem !important;
  margin: 0 0.5rem;
  padding: 0rem;

  svg {
    margin-right: 0 !important;
  }

  .loading-common {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }
}
