.smart-input-organization {
  position: relative;
  padding: 0.4rem 0 0.9rem 1.7rem;
  border: 0.2rem solid transparent;
  border-radius: 0.2rem;

  &__bar {
    position: absolute;
    left: 0.45rem;
    height: 7.2rem;
    border: 0.2rem solid transparent;
    border-radius: 0.4rem;
  }

  &__info {
    font-family: $font-primary-medium;
    font-size: 1.2rem;
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input {
    height: 4.8rem;
    width: 72%;
    padding: 0 1.3rem;
    border: 0.1rem solid map-get($colors, 'blue2');
    border-radius: 0.2rem;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    box-shadow: none;
    outline: none;

    &::placeholder {
      font-family: $font-primary;
      font-size: 1.6rem;
      color: map-get($colors, 'blue1');
    }

    &:disabled {
      font-family: $font-primary;
      border-color: transparent;
      width: 100%;
    }
  }

  &__actions {
    display: flex;
  }

  &__button {
    border: none;
    background-color: transparent;

    &:hover svg path {
      fill: map-get($colors, 'action');
    }

    &:focus {
      outline: none;
    }

    &.bin {
      margin-right: 2rem;
    }

    &-nav,
    &-collapse {
      display: flex;
      margin-top: 0.2rem;

      &::before {
        content: '';
        display: inline-block;
        height: 4.8rem;
        margin-right: 1rem;
        border: 0.1rem solid map-get($colors, 'blue4');
      }
    }

    &-collapse {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &::before {
        margin-right: 2.5rem;
      }
    }
  }

  &__secondary {
    &-action {
      display: flex;
      margin-top: 6px;

      &.disabled {
        svg path {
          fill: map-get($colors, 'gray600') !important;
        }

        .smart-input-organization__secondary-button {
          color: map-get($colors, 'gray600') !important;

          &:hover {
            background-color: transparent !important;
            cursor: not-allowed;
          }
        }
      }
    }

    &-button {
      width: 50% !important;
      margin-top: -0.1rem;
      background: none !important;

      span {
        margin-top: 0.5rem;
      }
    }
  }
}
