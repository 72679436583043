.form-radio {
  input,
  label {
    cursor: pointer;
  }

  input {
    transform: scale(1.8);
  }

  &__text {
    margin-left: 2rem;
    font-size: 1.6rem;
  }
}
