$theme-colors: (
  'primary': map-get($colors, 'primary'),
  'secondary': map-get($colors, 'secondary'),
);

.modal {
  font-size: 1.4rem;
  
  &.show {
    z-index: 9999;
  }
}

$spinner-border-width: 3px;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
