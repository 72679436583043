.smart-list {
  background-color: map-get($colors, 'white');

  &__list {
    max-height: 150px;
    margin-bottom: 1.5rem;
    list-style: none;
    overflow-y: auto;
  }

  @media screen and (max-height: 650px) {
    &__list {
      max-height: 100px;
    }
  }

  @media screen and (max-height: 550px) {
    &__list {
      max-height: 80px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.8rem;
    border: 0.1rem solid map-get($colors, 'gray0');
    cursor: pointer;

    &:hover {
      border-color: map-get($colors, 'blue2');
    }

    &-line1,
    &-line2 {
      display: flex;
      align-items: center;
      margin-left: 2.7rem;
      font-size: 1.4rem;
      line-height: 2.4rem;

      & > svg {
        height: 1.3rem;
        margin-right: 0.4rem;
      }

      & path {
        fill: map-get($colors, 'primary');
      }
    }

    &-line2 {
      font-family: $font-primary;
    }
  }

}
