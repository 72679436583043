.email-modified {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #030f40;
    background-color: map-get($colors, 'mainBackground');
  
    &_icon {
      width: 3.6rem;
      height: 3.6rem;
      margin-bottom: 2.5rem;
      fill: map-get($colors, 'green');
    }
  
    .button {
      margin-top: 2rem;
    }
  
    &_text-container {
      padding: 5.3rem 0;
      margin-top: 2.5rem;
      background-color: #ffffff;
      box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
        0 2px 4px 0 rgba(17, 95, 251, 0.1);
      font-family: $font-primary;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding-left: 4.2rem;
        padding-right: 4.2rem;
        text-align: center;
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 1.8rem;
  
        @include respond-down-to-media-breakpoint(small) {
          padding-left: 2rem;
          padding-right: 2rem;
        }
  
        .title {
          font-size: 3.2rem;
          margin-bottom: 2rem;
  
          @include respond-down-to-media-breakpoint(small) {
            font-size: 2rem;
          }
        }
        
        .loading-info {
          font-size: 3.2rem;
          margin: 2rem 0;
  
          @include respond-down-to-media-breakpoint(small) {
            font-size: 2rem;
          }
        }
      }
    }
  }
  