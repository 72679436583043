.searchBar {
  @include materialBox;
  display: flex;
  background-color: map-get($colors, 'white');
  padding: 3rem 2.2rem;

  &_label {
    margin-bottom: 0.6rem;
    color: map-get($colors, 'blue2');
    font-family: $font-primary-bold;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.057rem;
    line-height: 1.8rem;
  }

  &_datePicker {
    height: 4.8rem;
    width: 31.5rem;
    margin-left: 1.6rem;
  }

  &_separator {
    margin-left: 5.5rem;
    padding-left: 4.4rem;
    border-left: 0.2rem solid map-get($colors, 'blue4');
  }

  &_select {
    width: 20rem;
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;

    // sds overrides
    .css-vs1oli-control {
      border-color: map-get($colors, 'blue3');
    }

    &:hover {
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'linkHover');
      }
    }
  }

  &_inputSearch {
    width: 54.5rem;

    input {
      border-radius: 0;
      color: map-get($colors, 'primary');
      background: map-get($colors, 'white');
      border-color: map-get($colors, 'primaryDisabled');
      outline: 0;

      &::placeholder {
        color: map-get($colors, 'primary');
      }

      &:focus {
        border-color: map-get($colors, 'tabBorderColor') !important;
      }
    }
  }

  &_input {
    height: 4.8rem;
    width: 66.7rem;
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: 1324px) {
  .searchBar {
    display: grid;
    grid-template-columns: 33rem 1fr;
    row-gap: 2rem;

    &__byText {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  }
}

@media screen and (max-width: 654px) {
  .searchBar {
    grid-template-columns: 1fr;

    &__byStatus {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }

    &_inputSearch {
      width: auto;

      // sds overrides
      .input-group {
        width: auto !important;
      }
    }
  }
}
