.login-create {
  position: relative;
  width: 90%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: map-get($breakpoints, large)) {
    width: 920px;
  }

  &__title {
    padding-bottom: 4rem;
    font-size: 3.5rem;
    color: #030f40;
    text-align: left;
    width: 100%;
    margin-left: 0;

    &__bold {
      font-weight: 800;
    }

    @media screen and (max-width: map-get($breakpoints, 'xl')) {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px 4%;

    @media screen and (min-width: map-get($breakpoints, large)) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__item {
    width: 100%;

    @media screen and (min-width: map-get($breakpoints, large)) {
      width: 48%;
    }
  }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 23px;

        @media screen and (min-width: map-get($breakpoints, large)) {
            width: 48%;
        }

        & > .login-create__item {
            width: 100%;
        }

        & > .login-create__item:first-child {
            .button__secondary {
                margin-top: 4rem!important;
            }
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        margin: 3rem auto 2rem;
    }
}

.login-form {
  &__title {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    font-size: 2rem;
    font-weight: 700;
  }

  &__field {
    padding-top: 1.5rem;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-link {
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &__bottom {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    &__button {
      color: map-get($colors, 'blue1');
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      line-height: 1.7rem;
      background-color: transparent;
      border-color: transparent;

      @media screen and (min-width: map-get($breakpoints, large)) {
        font-size: 1.4rem;
      }
    }
  }
}

.create-form {
  &__title {
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    font-size: 2rem;
    font-weight: 700;
  }

  &__description {
    font-size: 1.5rem;
  }

  &__submit {
    padding: 1rem;
    border-width: 1px;
    background-color: white;
    color: #030f40;
    font-size: 1.5rem;
  }
}
