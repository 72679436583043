.attachment-institution {
  @include grid;
  row-gap: 3.5rem;
  padding: 0 4%;

  &__header {
    grid-column: 1 / 4;
    margin-top: 2.8rem;
  }

  &__save,
  &__validate {
    align-self: center;

    .button {
      margin-left: auto;
      width: 20rem;
      display: block;
    }
  }

  &__save {
    grid-column: 9 / 11;
  }

  &__validate {
    display: flex;
    grid-column: 9 / -1;
  }

  &__save > button,
  &__validate > button {
    width: 100%;
    height: 5rem;
  }

  &__filters {
    grid-column: 1 / 5;
    grid-row: 2;
  }

  &__organization {
    margin-bottom: 3rem;
  }

  &__institution {
    & .sz-select-overrides {
      margin-bottom: 0 !important;
    }
  }

  &__detail {
    grid-column: 11/5;

    .table {
      padding-left: 0 !important;

      th:not(.selection) {
          padding-left: 10px;
      }

      th {

        &.InstitutionCode {
          min-width: 130px;
        }
      }

      td {

        &:first-child {
          padding-left: 7px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 1.7rem;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  &__subtitle {
    font-family: $font-primary-bold;
    font-size: 3.2rem;
    line-height: 4rem;
  }

  &__label {
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }  

  .loading {
    grid-column: 9;
  }

  @media screen and (max-width: $tabletWidth) {
    @include grid-tablet;
    padding: 0 1rem;
    &__detail {
      grid-column: 9;
    }
    &__filters,
    &__header {
      grid-column: 1/9;
    }

    &__filters {
      .smart-select__search {
        width: initial;
      }
    }
    &__validate {
      grid-row: 1;
    }

    .loading {
      grid-row: 2;
    }
  }

  @media screen and (max-width: $mobileWidth) {
    @include grid-mobile;

    &__detail {
      grid-column: 1;
      grid-row: 3;
      width: 100%;
      max-width: 35.5rem;
    }

    &__filters,
    &__header {
      grid-column: 1;
    }

    &__header {
      grid-row: 1;
    }

    .loading {
      grid-column: 1;
      grid-row: 3;
      text-align: center;
    }

    &__validate {
      flex-direction: column;
      grid-column: 1;
      grid-row: 4;

      .button {
        min-width: 100%;
        &__save {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
