.activity-stacked-chart-header {
  width: 100%;
  min-height: 7.5rem;
  margin-bottom: 0.5rem;
  padding: 1.2em 2.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);

  &__total-synthesis {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__amount {
    font-size: 3.2rem;
    line-height: 4rem;
    white-space: nowrap;
  }

  &__percent {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__assets {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: bold;
  }
  &__assets-text {
    font-weight: normal;
  }

  &__supstring {
    vertical-align: super;
    font-size: 1.2rem;
  }

  &__date-range {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  &__year-range {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $mobileWidth) {
  .activity-stacked-chart-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__date-range {
      margin: 1rem 0;
    }
  }
}
