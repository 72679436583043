@font-face {
  font-family: 'DINPro';
  src: local('DINPro'), url(../../fonts/DINPro-Regular.woff) format('woff');
}

@font-face {
  font-family: 'DINPro-Medium';
  src: local('DINPro-Medium'),
    url(../../fonts/DINPro-Medium.woff) format('woff');
}

@font-face {
  font-family: 'DINPro-Bold';
  src: local('DINPro-Bold'), url(../../fonts/DINPro-Bold.woff) format('woff');
}

.font-light {
  font-family: $font-primary;
}

.font-medium {
  font-family: $font-primary-medium;
}

.font-bold {
  font-family: $font-primary-bold;
}

.underline {
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.main-title {
  padding-bottom: 0.5rem;
  color: map-get($colors, 'primary');
  font-family: $font-primary-bold;
  font-size: 3.2rem;
  line-height: 3.2rem;
  letter-spacing: 0;
}

.text-align-start {
  text-align: start !important;
}
