.click-card {
  box-shadow: rgba(17, 95, 251, 0.05) 0px 0px 0.25rem,
    rgba(17, 95, 251, 0.1) 0px 0.25rem 1rem;
  border-radius: 0.6rem;
  background-color: white;
  color: map-get($colors, 'primary');
  min-height: 13rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $font-primary-medium;
  font-size: 1.6rem;
  .tooltip-icon {
    position: absolute;
    right: 1rem;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid map-get($colors, 'tableHead');
    background: map-get($colors, 'blueLight');
    transition: transform 0.3s ease;
    > span:not(.tooltip-icon) {
      transform: translateY(-5px) scale(1.1);
      transition: transform 0.3s ease;
    }
    .tooltip-icon__text {
      display: block;
    }
  }

  &.selected {
    background: map-get($colors, 'blueLight');
    border: 1px solid map-get($colors, 'tableHead');
  }
}
