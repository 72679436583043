.create_messages {
  display: flex;
  flex-direction: column;

  &__mainContainer {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
  }
  &__switch {
    margin-bottom: 2rem;
  }
  &__titleContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__info {
    margin: 1rem 0;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &__topSection {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__preview-messages {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    min-height: 10rem;

    &__title {
      display: flex;
      flex: 0.2;
      align-items: center;
      font-size: 1.6rem;
      line-height: 1.7rem;
      font-family: $font-primary;
    }
  }

  &__switchButton {
    width: 20rem !important;
  }
  &__topSection-right {
    flex-direction: column;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &__DateContainer {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    z-index: 998;
  }
  &__expireDateLabel {
    margin: 1rem 0;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }
  &__topSection-left {
    display: flex;
    flex: 4;
    width: 100%;
  }
  &__bottomSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1.2rem;
  }
  &__Bottom-header {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7.5rem;
    align-items: center;
  }
  &__tableHeader {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    font-size: 1.6rem;
  }
  &__create-message {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &__buttons {
    width: 100%;
  }
  &__table {
    margin-top: 1.5rem;

    th:not(.selection) {
      padding-left: 10px;

      &.lastname,
      &.firstname {
        min-width: 120px;
      }

      &.createdAt,
      &.lastConnexionDate,
      &.dateModification,
      &.dateCreation {
        min-width: 180px;
      }
      &.username {
        min-width: 190px;
      }
      &.companyId {
        min-width: 268px;
      }
    }
  }

  &__boxlist {
    margin-left: 2rem;
  }
  &__checkbox {
    height: 2.8rem;
    color: map-get($colors, 'blue7');
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    .state {
      margin-left: 1rem;
    }
  }
  &__SelectedUser-container {
    height: 73rem;
  }
  &__left {
    margin-top: 1.8rem;
  }
}

@media screen and (max-width: $mobileWidth) {
  .create_messages {
    &__topSection {
      flex-direction: column;
    }
    &__topSection-right {
      flex-direction: column-reverse;
      justify-content: center;
      padding-left: 0;
      margin-top: 2rem;
    }
    &__preview-messages {
      flex-direction: column;
    }
    &__companies {
      padding: 1.6rem;
    }
    &__DateContainer {
      padding-top: 0;
      padding-bottom: 2rem;
    }
  }
}
