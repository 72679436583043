.back-home {
  display: flex;

  &__left,
  &__right {
    width: 50%;
    margin-top: 4rem;
    padding: 2rem 0;
  }

  &__link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 13.9rem;
    height: 2.4rem;
    font-size: 1.4rem;

    &:hover {
      color: map-get($colors, 'action');
    }

    &:hover path {
      fill: map-get($colors, 'action');
    }
  }

  &__icon {
    @include icon-size;
  }
}
