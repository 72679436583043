$padding-menu: 1.6rem;

.menu {
  display: flex;
  width: 100%;
  max-width: $desktopWidth;
  margin: 0 auto;
  padding: 0 2.5%;
  box-shadow: 0.3rem 0.3rem 1.2rem -0.4rem rgba(17, 95, 251, 0.1);

  height: 6rem;
  background-color: map-get($colors, 'white');
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.05rem;

  .notification-wrap {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;

    @include respond-down-to-media-breakpoint(small) {
      margin-left: 0;
      margin-right: auto;
    }
    .notification-icon {
      cursor: pointer;
      @include respond-down-to-media-breakpoint(small) {
        display: flex;
        align-items: center;
      }
      .sz-notification i {
        font-size: 2.5rem;
      }
      .label {
        display: none;
        @include respond-down-to-media-breakpoint(small) {
          display: block;
          margin-left: 1rem;
          &:hover {
            color: map-get($colors, 'linkHover');
          }
        }
      }
      .pastille {
        width: 1.3rem;
        height: 1.3rem;
        position: absolute;
        background-color: map-get($colors, 'primary');
        border-radius: 50%;
        top: 25%;
        left: 55%;
        @include respond-down-to-media-breakpoint(small) {
          top: 15%;
          left: 10%;
        }
      }
    }
    @keyframes menuAnim {
      0% {
        transform: translateY(-20px);
      }

      100% {
        transform: translateY(0px);
      }
    }
    .notification-block {
      border: 1px solid map-get($colors, 'tableHead');
      width: 30vw;
      max-width: 400px;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 120%;
      right: -2rem;
      background: map-get($colors, 'white');
      border-radius: 1rem;
      animation: menuAnim 1s ease 0s 1 normal forwards;

      .title {
        font-family: $font-primary-bold;
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 1rem;
        padding: 0.5rem 1rem;
      }
      .item {
        display: flex;
        height: 4vh;
        align-items: center;
        border-bottom: 1px solid map-get($colors, 'tabBorderHoverColor');
        padding: 0.5rem 1rem;
        color: map-get($colors, 'black');
        font-size: 1.5rem;
        &:hover {
          cursor: pointer;
          background-color: map-get($colors, 'blueLight');
        }
      }
      .item.notSeen {
        font-weight: bold;
        font-family: $font-primary-medium;
      }
      .empty {
        display: flex;
        align-items: center;
        height: 4vh;
        padding: 0.5rem 1rem;
        justify-content: center;
        margin-bottom: 1rem;
      }
      .more {
        text-align: center;
        margin: 10px 0;
        cursor: pointer;
      }
      .all {
        width: 100%;
        margin-top: 1rem;
        font-size: 1.5rem;
      }
      @include respond-down-to-media-breakpoint(small) {
        width: 70vw;
        left: 2rem;
        z-index: 2;
      }
    }
    .notification-block::before {
      content: '';
      position: absolute;
      top: -1rem;
      right: 2rem;
      border-width: 0 12px 12px 12px;
      border-style: solid;
      border-color: transparent transparent map-get($colors, 'white')
        transparent;
      z-index: 1;
      @include respond-down-to-media-breakpoint(small) {
        top: -1.1rem;
        right: 27.1rem;
      }
    }

    .notification-block::after {
      @include respond-down-to-media-breakpoint(small) {
        content: '';
        position: absolute;
        top: -1.3rem;
        right: 27rem;
        border-width: 0 13px 13px 13px;
        border-style: solid;
        border-color: transparent transparent map-get($colors, 'tableHead')
          transparent;
        z-index: 0;
        pointer-events: none;
      }
    }
  }

  &__main-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item.menu__user,
  .impersonnation {
    .menu {
      &__main-item {
        justify-content: space-between;
        min-width: 16rem;
      }

      &__iconTitle {
        width: 100%;
        display: flex;
        align-items: center;
        max-width: 20rem;

        & > svg {
          height: 4rem;
          width: 4rem;
          margin-left: 1rem;
          margin-right: 1.5rem;
          @include respond-down-to-media-breakpoint(large) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .impersonnation {
    margin-left: auto !important;
    @include respond-down-to-media-breakpoint(small) {
      margin-left: 0 !important;
      margin-right: auto !important;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 2.5%;
    cursor: pointer;

    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
    }
  }

  & > &__item:first-child {
    margin-right: 16%;
    font-size: 1.2rem;
    font-family: $font-primary-bold;
    line-height: 1.2rem;

    @include respond-to-between-media-breakpoint(small, large) {
      margin-right: 4%;
    }

    & > a {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
    }
  }

  & > &__item:last-child {
    margin: 0 0 0 1rem;

    @include respond-down-to-media-breakpoint(small) {
      margin-left: 0;
    }

    & > svg {
      margin-right: 1rem;
    }
  }

  & &__item:not(:last-child) &__icon {
    display: none;
  }

  &__sub-items {
    position: absolute;
    top: 6rem;
    padding: 0 $padding-menu;
    background-color: map-get($colors, 'white');
    list-style: none;
    visibility: hidden;
    z-index: 3;
    white-space: nowrap;
    min-width: 100%;

    &--opened {
      visibility: visible;

      @include respond-down-to-media-breakpoint(small) {
        position: static;
        width: 100%;
        margin: 1rem 0 0;
      }
    }
  }

  &__sub-item {
    display: flex;
    align-items: center;
    min-height: 4.1rem;

    &-link {
      min-height: 4.1rem;
      padding-top: 1.2rem;
      width: 100%;
    }

    @include respond-down-to-media-breakpoint(small) {
      align-items: stretch;
    }
  }

  &__sub-item:not(:last-child) {
    border-bottom: 0.1rem solid map-get($colors, 'primary');
  }

  &__item:hover,
  &__item:hover path,
  &__item:hover > a,
  .menu__sub-item:hover > a {
    color: map-get($colors, 'linkHover');

    .menu__svg {
      path {
        fill: map-get($colors, 'linkHover');
      }
    }

    .menu__iconTitle {
      svg {
        fill: map-get($colors, 'linkHover');
      }
    }
  }

  &__item {
    &.menu__user {
      &:hover {
        .menu__iconTitle {
          path {
            color: map-get($colors, 'primary');
            fill: map-get($colors, 'primary');
          }
        }
      }
    }
  }

  &__logo {
    width: 23rem;
  }

  &__svg {
    height: 0.8rem;
    margin-left: 0.3rem;
  }

  &__burger {
    display: none;
  }
  &__impersonnationExit {
    margin-top: 1rem;
    margin-left: -14rem;
    margin-right: 2rem;
    @include respond-down-to-media-breakpoint(small) {
      margin-left: 0;
      margin-right: 0;
    }
    height: 4.2rem !important;
    width: 22rem !important;
    font-family: $font-primary-medium;
    font-size: 1.4rem !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;
    padding: 0 !important;
  }
  &__exitButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2rem 0 0;

    &-icon {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 1209px) {
  .menu {
    &__brand {
      font-size: 1rem;
    }
  }
}

@include respond-down-to-media-breakpoint(small) {
  $menuWidth: 300rem;
  $sidebarEffect: margin-left 0.2s;

  .menu {
    position: absolute;
    top: 5rem;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin-left: -$menuWidth;
    transition: $sidebarEffect;
    z-index: 100;

    &--opened {
      margin-left: 0;
      transition: $sidebarEffect;
    }

    &--closed {
      visibility: hidden;
    }

    &__item {
      padding: 1rem 0;
    }

    &__item {
      align-items: flex-start;
    }

    &__icon {
      margin-right: 1rem;
    }

    &__main-item {
      min-width: auto;
    }

    &__iconTitle {
      width: 100%;
      display: flex;
      align-items: center;
      max-width: 17rem;

      & > svg {
        margin-right: 1rem;
      }
    }

    & > &__item:first-child {
      display: none;
    }

    & &__item:not(:last-child) &__icon {
      display: block;
    }

    &__burger {
      display: flex !important;
      background-color: map-get($colors, 'white');

      .menu-icon {
        width: 3rem;
        height: 2.4rem;
        fill: map-get($colors, 'primary');

        &:hover {
          fill: map-get($colors, 'linkHover');
        }
      }

      .menu__item {
        margin: 0.4rem auto;

        .menu__logo {
          display: block;
        }
      }
    }
    .menu__item {
      & > a {
        display: flex;
        align-items: center;
      }
    }
  }
}
