@mixin respond-down-to-media-breakpoint($breakpoint, $grid-breakpoints: $breakpoints) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin respond-up-to-media-breakpoint($breakpoint, $grid-breakpoints: $breakpoints) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@function breakpoint-min($name, $breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min and $min > 0, $min + 0.02, null);
}

@function breakpoint-max($name, $breakpoints) {
  $max: map-get($breakpoints, $name);

  @return if($max and $max > 0, $max - 0.02, null);
}

@mixin respond-to-between-media-breakpoint($lower, $upper, $grid-breakpoints: $breakpoints) {
  $min: breakpoint-min($lower, $grid-breakpoints);
  $max: breakpoint-max($upper, $grid-breakpoints);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}
