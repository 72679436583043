.reset-password-form {
  .form {
    box-shadow: none;

    &_advice {
      margin: 0 0 2rem;
    }

    &__advice-indication {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    &__submit {
      @include respond-down-to-media-breakpoint(small) {
        align-self: flex-end;
      }
    }
  }

  &__icon {
    fill: map-get($colors, 'primary');
    color: map-get($colors, 'primaryDisabled');
  }

  &__error_message {
    margin-right: 0.5rem;
  }

  &__icon--valid {
    fill: map-get($colors, 'primary');
    color: map-get($colors, 'primaryButtonColor');
  }

  &__cgu-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;

    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
    }

    p {
      margin: 0 2rem 0 0;
      text-align: justify;
      font-size: 1.2rem;

      a {
        text-decoration: underline;
      }

      &:before {
        content: '* ';
        display: inline-block;
        margin-right: 0.3rem;
      }

      @include respond-down-to-media-breakpoint(large) {
        margin-right: 0;
      }

      @include respond-down-to-media-breakpoint(small) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    .submit-with-star {
      position: relative;

      &:after {
        content: '*';
        display: inline-block;
        position: absolute;
        right: -1.3rem;
        top: -1rem;
        font-size: 2rem;
      }
    }
  }

  &__rule-tooltip {
    line-height: 2.8rem;
  }
}
