.errorbody {
  margin-block-start: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #030f40;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);

  &_image {
    width: 30rem;
    margin-block: 2rem;

    @include respond-down-to-media-breakpoint(small) {
      width: 24rem;
      height: auto;
    }
  }

  &_text-container {
    width: 100%;
    height: 33.6rem;
    background-color: #ffffff;
    font-family: $font-primary;
    &--width-auto {
      width: auto;
    }

    @include respond-down-to-media-breakpoint(large) {
      padding: 0 2rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      padding-inline: 6.2rem;
      padding-block-end: 2rem;
      text-align: center;
      font-size: 1.6rem;
      letter-spacing: 0;
      line-height: 1.8rem;

      .title {
        font-size: 3.2rem;

        @include respond-down-to-media-breakpoint(small) {
          font-size: 2rem;
        }
      }

      img[alt="header-avatar"] {
        width: 25%;
      }

      .link {
        font-weight: bold;
        text-transform: uppercase;
        color: map-get($colors, "primary");
        cursor: pointer;
      }
    }
  }
}
