.profile {
  margin: 0 0 3.4rem;

  &_backgroundcolor {
    background-color: map-get($colors, 'mainBackground');
    margin-top: .25rem;
  }

  &__main-content-wrapper {
    display: flex;    
    margin-bottom: 2rem;

    .loading {
      margin: 0;
      padding: 3rem 0;
    }

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
    }
  }

  &__left-portion {
    width: 60%;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__right-portion {
    width: 38%;
    margin-left: 2%;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
      margin-left: 0;
    }
  }    

  &__bottom-section {
    width: 60%;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
    }
  }

  &__marginFooter {
    margin: 0 0 2rem;
  }

  .main-title {
    padding-bottom: .75rem;
  }

  &_infosPage {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.8rem;
  }
}
.col_padding {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.empty_col_padding {
  padding-left: 14px !important;
}
.profile_content {
  @media only screen and (max-width: 760px) {
    display: inline-grid !important;
    .empty_col_padding {
      padding-top: 14px !important;
    }
  }
  display: flex;
  margin-bottom: 15.9rem;

  &_form {
    justify-content: center;
    margin-top: 3.8rem;
    border-left: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
  }

  &_formLabel {
    // margin-left: 3rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    font-size: 1.4rem;
  }

  &_backgroundColor {
    background-color: white;
    height: fit-content;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  &_backgroundColorRightSide {
    background-color: white;
    height: fit-content;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
  }

  &_formTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &_formTitleContainer {
    width: 90%;
    margin: 0 auto;
  }

  &_formInput {
    width: 100%;
    height: 5.8rem;
    background-color: #e9ecef;
    border: none;
    text-indent: 2.1rem;

    &::placeholder {
      color: map-get($colors, 'gray600');
    }
  }
  &_formInputEdit {
    width: 100%;
    height: 5.8rem;
    text-indent: 2.1rem;
  }

  &_buttonSubmit {
    width: 16.8rem;
    height: 4.8rem;    
  }

  &_infosTextContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 3.6rem;

    &_textStyle {
      color: map-get($colors, 'gray600');
      font-family: $font-primary;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      line-height: 1.2rem;
      flex-wrap: wrap;
      width: 80%;
      margin-right: 5rem;
    }
    
    .loading {
      padding: 0;
    }
  }

  &_expirationPWDText {
    display: block;
    color: map-get($colors, 'gray600');
    font-family: $font-primary;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.2rem;

    @include respond-down-to-media-breakpoint(small) {
      text-align: center;
    }
  }

  &_bottomButtonContainer {
    display: flex;
    width: 85%;
    margin-top: 1.5rem;
    justify-content: space-between;
  }

  &_rightCardRightBlock {
    margin-right: 2.5rem;
    margin-bottom: 3.7rem;
  }

  &_rightCardLeftBlock {
    margin-left: 2.5rem;
  }

  &_leftCardDisplay {
    margin-top: 5rem;
    justify-content: space-between;
    display: flex;
  }

  &_leftCardBlockDisplay {
    display: grid;
    justify-content: space-between;
  }

  &_rightCardTextDiv {
    margin-top: 5rem;
    display: grid;
  }

  &_alignRightDiv {
    margin-left: 50%;
    width: 100%;
  }

  &_leftCardTextStyle {
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem;
  }

  &_separator {
    margin-left: 2.5rem;
  }

  &_jumpline {
    height: 3rem;
  }

  &_buttonBottomPageTextStyle {
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 10.4rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
  }

  &_divInput {
    margin: 0 auto;
    width: 90%;
  }
  
  &_inputBtn {
    display: flex;
    align-items: center;

    .buttonSquareIcon {
      height: 5.8rem !important;
      width: 5.8rem !important;
      margin: 0 0.5rem;
      padding: 0rem;
    }
  }

  &_divMainContainer {
    width: 100%;
  }
  &_resetpwd,
  &_removeacc {
    color: map-get($colors, 'blue1');
    font-size: 1.4rem !important;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
    background-color: transparent;
    border-color: transparent;
  }
  &_resetpwd:hover,
  &_removeacc:hover {
    text-decoration: underline;
  }

  &_bottomPageContainer {
    width: 100%;
    background-color: map-get($colors, 'mainBackground');
  }

  &_divSeparator {
    height: 2.1rem;
  }

  @include respond-down-to-media-breakpoint(small) {
    &_bottomButtonContainer {
      display: block;
      width: 100%;
      text-align: center;

      button {
        margin-right: 1.2rem;
      }
    }
    &_resetpwd {
      margin-bottom: 1.5rem;
    }
  }
}

.profile_button {
  margin-top: 4.3rem;

  &_myprofile {
    width: 12.967rem;
    height: 3.6rem;
    border-color: map-get($colors, 'blue3') !important;
  }

  &_myperimeter {
    width: 16.767rem;
    height: 3.6rem;
    margin-left: 1rem;
    border-color: map-get($colors, 'blue3') !important;
  }

  &--selected {
    background-color: map-get($colors, 'blue4') !important;
    border: 0.1rem solid map-get($colors, 'primary') !important;
  }
}
