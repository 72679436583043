.dashboard-suez-4-you {
    padding: 2.6rem;
    background-color: map-get($colors, "datePickerSpanSelected");

    &__title {
        font-size: 2rem;
        font-family: $font-primary;
        display: flex;
        align-items: center;
    }

    &__description {
        margin-block-start: 2.2rem;
        font-size: 1.6rem;
    }

    &__button {
        margin-block-start: 2.6rem;
        display: flex;
        justify-content: center;
    }

    &__logo {
        height: 60%;
    }

    &__modale {
        &__container {
            padding-inline: 2.6rem;
            padding-block: 2rem;
        }
        .close {
            font-size: 2.8rem;
        }
        .modal-header {
            padding-inline: 0;
            margin-block-end: 2rem;
            border-block-end: none !important;
        }
        &__title {
            font-size: 2rem;
            font-family: $font-primary-bold;
        }   
        &__grid {
            padding: 2.6rem;
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 10fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            row-gap: 5rem;
            column-gap: 1rem;
            background-color: map-get($colors, "tableBorder");
            &__item {
                height: 6rem;
                display: flex;
                flex-direction: column;
            }
            .left {
                align-items: center;
            }
            .right {
                margin-inline-start: 1rem;
                :first-child {
                    font-family: $font-primary-bold;
                }
                :nth-child(2) {
                    margin-block-start: 1rem;
                }
                @media screen and (min-width: map-get($breakpoints, large)) {
                    width: 100%;
                }
            }
        }
        &__actions {
            margin-block-start: 3rem;
            height: 11rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            a:nth-child(2) {
                margin-block-start: 2rem;
            }
            @media screen and (min-width: map-get($breakpoints, large)) {
                flex-direction: row;
                height: 6rem;
                a:nth-child(2) {
                    margin-block-start: 0;
                }
            }
        }
        &__actions > a {            
            padding: 2rem;
            height: 2rem;
            font-size: 1.6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media screen and (min-width: map-get($breakpoints, large)) {
                padding: 2rem;
                height: 100%;
                width: 38%;
                font-size: inherit;
            }    
        }
    }
}