.user-management {
  display: flex;
  flex-wrap: wrap;

  &_loading {
    margin-top: 5rem;
  }

  &_marginFooter {
    margin: 0 0 2rem;
  }

  &_yearCollapse {
    margin-top: -0.8rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    text-align: center;
  }

  &_yearCheckbox {
    position: absolute;
    z-index: 1;
    margin-top: 0.2rem;
  }

  &__switchButton {
    height: 3.6rem !important;
    width: 17.4rem !important;
    margin-right: 0.5rem !important;
    font-family: $font-primary-bold !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.05rem;
    outline: 0 !important;
    margin-bottom: 2rem;

    span {
      font-family: $font-primary-bold !important;
    }

    &_width {
      width: 12.8rem !important;
    }
  }

  &_switchButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  &_switchTab {
    display: flex;

    .button {
      padding: 0;
    }
  }

  &__nbSelected {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
  }

  &_downloadBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    margin: 2rem 0 0;

    &-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 13rem;

      &-icon {
        height: 2.5rem;
        width: 2.4rem;
        margin-right: 1rem;
        color: map-get($colors, 'primary');
      }
    }
    &_export-users {
      margin: 0 1rem;
      width: 27rem;
      @include respond-down-to-media-breakpoint(small) {
        margin-bottom: 1rem;
      }
    }
  }
  &_createUserButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2rem 0 0;
    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
    }

    &-icon {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
      & path {
        fill: map-get($colors, 'primary');
      }
    }
  }

  &_marginButton {
    margin-right: 1rem;
  }

  &_cardPeriod {
    margin-top: 1.9rem;
    text-transform: lowercase;
  }

  &_leading-small {
    line-height: 1.4rem;
  }

  &_leading-medium {
    line-height: 2.2rem;
  }

  &_mt-s {
    margin-top: 2.5rem;
  }

  &_mt-xs {
    margin-top: 0.4rem;
  }

  &_accordionTitle {
    display: flex;
  }

  &_cards {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, 31.1rem);
    width: 100%;
    margin-top: 2.5rem;
  }

  &_card {
    display: flex;
    flex-direction: column;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    padding: 1.6rem 1.5rem;
    background-color: map-get($colors, 'white');
    color: map-get($colors, 'blue1');
    box-shadow: 0.3rem 0.3rem 1.2rem -0.4rem rgba(17, 95, 251, 0.1);
  }

  &_cardTextNumber {
    margin-left: 2rem;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    text-decoration: none;
  }

  &_cardTitle {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &_cardPrice {
    margin-top: 2.5rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
  }

  &_CardStatus {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 3.2rem;
    width: 7.3rem;
    margin-left: auto;
    background-color: map-get($colors, 'mainBackground');
    color: map-get($colors, 'primary');
    font-size: 1.2rem;
    letter-spacing: 0.05rem;

    &--inactive {
      background-color: inherit;
    }
  }

  &_errorAlert {
    margin: 2rem 0;
    font-size: 1.8rem;
  }

  &_downloadBarButtonIcon-2 {
    height: 4.8rem !important;
    width: 4.8rem !important;
  }
  &_separator {
    border-color: white !important;
  }

  &_cardButtonContainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 1.9rem;
  }

  &_iconRightContainer {
    margin-top: -1.5rem;
    padding: 1.6rem 1rem;
    border: 0.1rem solid map-get($colors, 'blue4');
    border-radius: 0.2rem;
    background-color: map-get($colors, 'white');

    svg {
      height: 0.8rem;
    }
  }

  &_CollapseContainer {
    display: flex;
    border: none;
  }

  &_accordionToggle {
    border-width: 0;
    background-color: map-get($colors, 'mainBackground');
    outline: 0 !important;
  }

  &_switchDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.6rem;
    width: 8.6rem;
    border-radius: 0.2rem;
    background-color: map-get($colors, 'blue4');
    outline: none;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.4rem;
      width: 2.4rem;
      margin: 0 0.5rem;
      border-radius: 0.2rem;
      border: 0.1rem solid transparent;

      &:focus {
        outline: 0;
      }

      &:hover {
        border-color: map-get($colors, 'linkHover');
      }

      &--active {
        background-color: map-get($colors, 'white');
      }
    }

    &_icon {
      height: 1.6rem;
      width: 1.6rem;

      path {
        fill: map-get($colors, 'blue2');
      }
    }

    &__button--active &_icon {
      path {
        fill: map-get($colors, 'primary');
      }
    }

    &_avoir {
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  &__table {
    margin-top: 1.5rem;
    background-color: white;
    box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
      0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);

    .super-admin-table {
      margin-inline: 1.2rem;
    }

    .filter-desktop {
      width: 13rem;
    }

    .filter_module_mobile {
      padding: 1.2rem;
    }

    .select-filter {
      .searchBar_select {
        width: 16rem;
      }
    }

    .DateRangePicker div.DateInput {
      width: 63px;
      .DateInput_input {
        text-align: center;
      }
    }

    th:not(.selection) {
      padding-left: 10px;

      &.lastname,
      &.firstname {
        min-width: 120px;
      }

      &.dateModification,
      &.dateCreation {
        min-width: 145px;
        .table__filter {
          text-align: center;
        }
      }

      &.createdAt {
        min-width: 145px;
      }

      &.lastConnexionDate {
        min-width: 175px;
        .table__filter {
          text-align: center;
        }
      }

      &.familleOrganisation {
        min-width: 220px;
      }

      &.controlButtons {
        min-width: 136px;
      }

      &.companyId {
        min-width: 268px;
      }

      &.state {
        min-width: 160px;
      }

      &.statut {
        min-width: 215px;
      }

      &.nomOrganisation {
        min-width: 250px;
      }

      &.content {
        min-width: 170px;
      }

      &.username {
        min-width: 190px;
      }
    }

    td {
      .user-management__createdAtCell,
      .user-management__OrgacreatedAtCell {
        text-align: center;
      }
    }
  }

  &__anchor {
    position: absolute;
    top: 15rem;
    left: 0;
  }

  &__OrgaStatusCell {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 20rem;

    & > div {
      width: 100%;
    }
  }

  &_Orgaselect {
    width: 100%;
    min-width: 14.3rem;
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;

    // sds overrides
    .css-vs1oli-control {
      border-color: map-get($colors, 'blue3');
    }

    &:hover {
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'linkHover');
      }
    }
  }

  &__lastConnexionCell {
    width: 2rem;
  }

  &__currencyCell {
    width: 2rem;
  }

  &__statusCell {
    display: flex;
    align-items: center;
  }

  &__subStatusCell {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__ButtonCell {
    display: flex;
    flex-direction: row;
    justify-content: left !important;

    .__actions {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1;
      background-color: white;
      padding: 0;
      border: 1px solid map-get($colors, 'tabBorderHoverColor');
      width: max-content;
      list-style: none;
      li {
        padding: 1rem 1.5rem;
        &:hover {
          background-color: map-get($colors, 'tabBorderHoverColor');
        }
      }
    }

    .__actions.show {
      display: block;
    }
  }
  &__updatedAtOrga {
    width: 20rem;
  }

  &_separator {
    margin-left: 5.5rem;
    padding-left: 4.4rem;
    border-left: 0.2rem solid map-get($colors, 'blue4');
  }

  &_select {
    width: 125px;
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;

    // sds overrides
    .css-vs1oli-control {
      border-color: map-get($colors, 'blue3');
    }

    &:hover {
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'linkHover');
      }
    }
  }

  &__tooltip {
    margin-left: auto;
    margin-right: 14px;

    .tooltip-icon {
      margin: 0;
      top: 3px;

      &__text {
        width: 17rem;
        transform: translate(-100%, 1.5rem);
        word-break: break-word;

        &::after {
          top: 78%;
          left: auto;
          right: -16px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
