.annimate-btn-icon {
  svg {
    transition: 0.1s ease-in-out;
  }
  &:hover {
    background-color: #bbdbfd;
    svg {
      transition: 0.1s ease-in-out;
      transform: scale(1.3);
    }
  }
}

.annimate-btn-arrow {
  overflow: hidden;
  &:hover {
    svg {
      animation: aniArrowBackAndForth 1s cubic-bezier(0, 0.6, 1, 0.4);
    }
  }
}

@keyframes aniArrowBackAndForth {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}
