.breadCrumbs {
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: .05rem;
  color: map-get($colors, 'black');

  &__item {
    &--selected {
      font-family: $font-primary-bold;
      color: map-get($colors, 'primary');
    }
  }

  &__link {
    &:hover {
      color: map-get($colors, 'linkHover');
    }
  }

  &__arrow {
    height: 1.2rem;
  }
} 
