$borderCollapse: 0.1rem solid;

.nested-collapse {
  &__header {
    display: flex;
    align-items: center;
    height: 8.4rem;
    margin-top: 0.3rem;
    background-color: map-get($colors, 'white');
    border: $borderCollapse transparent;    

    .pretty {
      margin-right: 1em;
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;

      & .checkbox {
        margin-right: 1.8rem;
      }
    }

    & button:focus {
      outline: none;
    }
  }

  &__info {
    flex-grow: 2;
  }

  &__title {
    font-family: $font-primary-bold;
    font-size: 1.6rem;
  }

  &__subtitle {
    margin-top: -0.3rem;
    color: map-get($colors, 'gray1');
    font-size: 1.2rem;
  }

  &__body {
    border: $borderCollapse transparent;
    border-top: none;
    border-bottom: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &-inner {
      padding: 0 0.3rem 0.3rem;
    }

    .table {

      tbody {

        td {
          padding-left: 10px;

          &:last-child {
            min-width: 140px;
          }
        }
      }
    }

    &--active {
      max-height: 20000rem; // hack to simulate accordion just in CSS
      border-bottom: $borderCollapse transparent;
      transition: max-height 0.4s ease-in;
    }

    &-content {
      display: flex;
      align-items: center;

      & .checkbox {
        margin-right: 1.8rem;
      }
    }
  }

  &__collapsed {
    height: 7.4rem;
    margin: 0 1.2rem 0 0.4rem;
    border-left: 0.4rem solid transparent;
    border-radius: 0.4rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    width: 6.4rem;
    height: 5.2rem;
    border-left: 0.2rem solid map-get($colors, 'blue4');

    > button {
      background: none;
      border: none;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .nested-collapse {
    &__body-inner:first-child {
      overflow-x: scroll;
    }
  }
}
