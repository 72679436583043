.borderColorLvl--1 {
  border-color: map-get($colors, 'borderFrameColor') !important;
}
.borderColorLvl--2 {
  border-color: $organizationLvl2Color !important;
}
.borderColorLvl--3 {
  border-color: $organizationLvl3Color !important;
}
.borderColorLvl--4 {
  border-color: $organizationLvl4Color !important;
}
.borderColorLvl--5 {
  border-color: $organizationLvl5Color !important;
}
.borderColorLvl--6 {
  border-color: $organizationLvl6Color !important;
}

.borderLeftColorLvl--1 {
  border-left-color: $organizationLvl1Color !important;
}
.borderLeftColorLvl--2 {
  border-left-color: $organizationLvl2Color !important;
}
.borderLeftColorLvl--3 {
  border-left-color: $organizationLvl3Color !important;
}
.borderLeftColorLvl--4 {
  border-left-color: $organizationLvl4Color !important;
}
.borderLeftColorLvl--5 {
  border-left-color: $organizationLvl5Color !important;
}
.borderLeftColorLvl--6 {
  border-left-color: $organizationLvl6Color !important;
}

.colorLvl--1 {
  color: $organizationLvl1Color !important;
}
.colorLvl--2 {
  color: $organizationLvl2Color !important;
}
.colorLvl--3 {
  color: $organizationLvl3Color !important;
}
.colorLvl--4 {
  color: $organizationLvl4Color !important;
}
.colorLvl--5 {
  color: $organizationLvl5Color !important;
}
.colorLvl--6 {
  color: $organizationLvl6Color !important;
}

.fillColorLvl--1 {
  path {
    fill: $organizationLvl1Color !important;
  }
}
.fillColorLvl--2 {
  path {
    fill: $organizationLvl2Color !important;
  }
}
.fillColorLvl--3 {
  path {
    fill: $organizationLvl3Color !important;
  }
}
.fillColorLvl--4 {
  path {
    fill: $organizationLvl4Color !important;
  }
}
.fillColorLvl--5 {
  path {
    fill: $organizationLvl5Color !important;
  }
}
.fillColorLvl--6 {
  path {
    fill: $organizationLvl6Color !important;
  }
}

.backgroundHoverColorLvl--1 {
  &:hover {
    background-color: rgba($organizationLvl1Color, 0.5) !important;
  }
}
.backgroundHoverColorLvl--2 {
  &:hover {
    background-color: rgba($organizationLvl2Color, 0.5) !important;
  }
}
.backgroundHoverColorLvl--3 {
  &:hover {
    background-color: rgba($organizationLvl3Color, 0.5) !important;
  }
}
.backgroundHoverColorLvl--4 {
  &:hover {
    background-color: rgba($organizationLvl4Color, 0.5) !important;
  }
}
.backgroundHoverColorLvl--5 {
  &:hover {
    background-color: rgba($organizationLvl5Color, 0.5) !important;
  }
}
.backgroundHoverColorLvl--6 {
  &:hover {
    background-color: rgba($organizationLvl6Color, 0.5) !important;
  }
}

.backgroundColorFullLvl--1 {
  background-color: rgba($organizationLvl1Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl1Color, 1) !important;
  }
}
.backgroundColorFullLvl--2 {
  background-color: rgba($organizationLvl2Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl2Color, 1) !important;
  }
}
.backgroundColorFullLvl--3 {
  background-color: rgba($organizationLvl3Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl3Color, 1) !important;
  }
}
.backgroundColorFullLvl--4 {
  background-color: rgba($organizationLvl4Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl4Color, 1) !important;
  }
}
.backgroundColorFullLvl--5 {
  background-color: rgba($organizationLvl5Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl5Color, 1) !important;
  }
}
.backgroundColorFullLvl--6 {
  background-color: rgba($organizationLvl6Color, 0.9) !important;
  &:hover {
    background-color: rgba($organizationLvl6Color, 1) !important;
  }
}
