.tooltip-button {
  position: relative;

  &__text {
    display: none;
    position: absolute;
    bottom: -2rem;
    left: -8rem;
    width: 42rem;
    padding: 0.8rem;
    background-color: map-get($colors, 'primary');
    color: map-get($colors, 'white');
    font-size: 1.6rem;
    line-height: 1.8rem;
    border-radius: 0.4rem;
    transform: translate(-33%, -3.5rem);
    z-index: 1;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      left: 70%;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 1.1rem solid transparent;
      border-right: 1.1rem solid transparent;
      border-top: 1.1rem solid map-get($colors, 'primary');
      clear: both;
    }
  }

  &:hover &__text {
    display: block;
  }
}

.tooltip-progressbar {
  opacity: 1 !important;
  .arrow {
    &::before {
      border-top-color: white !important;
    }
  }
  .tooltip-inner {
    background-color: white;
    color: map-get($colors, 'primary');
    font-size: 1.5rem;
    box-shadow: rgba(17, 95, 251, 0.05) 0px 0px 0.25rem,
      rgba(17, 95, 251, 0.1) 0px 0.25rem 1rem;
  }
}
