.header {
  position: relative;
  padding: 2rem 0;
  background-color: map-get($colors, 'white');
  text-align: center;
  box-shadow: 0 0.3rem 0.5rem -0.5rem rgba(0, 0, 0, 0.4);
  width: 100%;
  
  &_logo_click, &_logo_disabled {
    width: 23rem;
  }
  
  &_logo_click:hover {
    cursor: pointer;
  }

  &_tagline {
    color: map-get($colors, 'primary');
    display: block;
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 500;
  }
}
