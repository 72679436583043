$font-primary: 'DINPro';
$font-primary-medium: 'DINPro-medium';
$font-primary-bold: 'DINPro-bold';

$colors: (
  'primary': #030f40,
  'secondary': #a2c617,
  'white': #ffffff,
  'black': #000000,
  'blue1': #3d466c,
  'blue2': #767c97,
  'blue3': #abafbf,
  'blue4': #d5d6de,
  'blue5': #1b2028,
  'blue6': #020d41,
  'blue7': #030f40,
  'blueElectric': #2e88ff,
  'blueMedium': #54b8ff,
  'blueLight': #d6f1fb,
  'gold': #deb861,
  'gray0': #f4f8ff,
  'gray1': #6c757d,
  'gray2': #e9ecef,
  'gray100': #f8f9fa,
  'gray500': #adb5bd,
  'gray600': #6c757d,
  'gray700': #b6bcc3,
  'Mischka': #dcdee4,
  'gray1FIXME': #979797,
  'gray': #f2f2f2,
  'green1': #a2c617,
  'green2': #9bcd41,
  'ligthGreen': #e8f4c1,
  'orange1': #ff8363,
  'orange2': #fff4d9,
  'purple1': #c65bab,
  'redDark': #800e21,
  'error': #eb4a65,
  'action': #699c00,
  'gradientBegin': #d5f02d,
  'gradientEnd': #aadc14,
  'mainBackground': #f4f6fb,
  'tableHead': #77b7fc,
  'tableSeparatorColor': #f4f6fb,
  'primaryButtonColor': #77b7fc,
  'primaryHoverBackground': #6095d6,
  'primaryDisabled': #d0d8e6,
  'tabBorderColor': #666,
  'tabBorderHoverColor': #bbdbfd,
  'tablesortEnabled': #f67536,
  'tableSortDisabled': #030f40,
  'linkHover': #77b7fc,
  'borderFrameColor': #77b7fc,
  'tableBorder': #f4f6fb,
  'tableOddBackground': #030f40,
  'tableEvenBackground': #d0d8e6,
  'paginationBorder': #77b7fc,
  'paginationBorderHover': #6095d6,
  'paginationDisabledBorder': #d0d8e6,
  'paginationColor': #030f40,
  'datePickerSelected': #77b7fc,
  'datePickerSpanSelected': #bbdbfd,
  'datePickerSelectedBorder': #f4f6fb,
  'datePickerSelectedTextColor': #030f40,
  'impersonateBackground': #ffc229,
  'datePickerBorder': #484848,
);

@each $key, $value in $colors {
  .text-#{$key} {
    color: $value;
  }
  .bg-#{$key} {
    background-color: $value;
  }
}

// To remove after enhacing styles.
$mobileWidth: 34.3rem;
$tabletWidth: 76.8rem;

$desktopWidth: 1440px;
$breakpoints: (
  'small': 836px,
  'large': 996px,
  'xl': 1200px,
  'xxl': 1400px
);

$bpPerimeterFilterColumnMode: 744px;

$organizationLvl1Color: map-get($colors, 'green2');
$organizationLvl2Color: map-get($colors, 'orange1');
$organizationLvl3Color: map-get($colors, 'purple1');
$organizationLvl4Color: map-get($colors, 'blueElectric');
$organizationLvl5Color: map-get($colors, 'gold');
$organizationLvl6Color: map-get($colors, 'redDark');

$backgroundButtonGradient: linear-gradient(
  to right,
  map-get($colors, 'gradientBegin'),
  map-get($colors, 'gradientEnd')
);

.app {
  white-space: pre-line;
}

$buttonHeight: 5rem;
$buttonBorder: 0.1rem solid map-get($colors, 'blue3');
