.screen-sign-up {
  .menu {
    display: flex;
  }
  .menu > .menu__item:first-child {
    flex: 0.33;
    margin-right: 0;
  }
  .menu > .menu__item:last-child {
    margin: 0;
  }

  .sub-menu__item {
    flex: 0.11;
    align-items: center;
    justify-content: center;
  }
  &_link {
    margin-top: -2rem;
    min-width: 16rem;
    margin-left: 7rem;
    margin-bottom: 2rem;
    &_button {
      color: map-get($colors, 'blue1') ;
      font-size: 1.4rem !important;
      font-weight: 500;
      letter-spacing: 0.05rem;
      line-height: 1.7rem;
      background-color: transparent;
      border-color: transparent;
    }
    &_button:hover {
      text-decoration: underline;
    }
  }
}

.user-sign-up-wrapper {
  &__errorMessage {
    font-size: 1.6rem;
    margin: 0.6rem 0 1.6rem 1rem;
  }
  &__link {
    font-size: 1.6rem;
    color: map-get($colors, "linkHover");
  }

  .form {
    margin: 1.2rem !important;
  }
}

.user-sign-up-step2 {
  &__form-inline {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
    }
    .form__label > div:first-child {
      width: 100%;
      display: flex;
    }
    .tooltip-icon {
      &__text {
        @include respond-down-to-media-breakpoint(large) {
          left: -3rem;
        }
      }
    }
    .input_mandatory_indicator {
      flex: 1;
      text-align: left;
      align-self: auto;
    }
  }

  &__input-inline {
    flex: 1;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__document-select {
    display: flex;
    background-color: map-get($colors, 'white');

    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;

    // sds overrides
    .css-vs1oli-control {
      border-color: map-get($colors, 'blue3');
    }

    &:hover {
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'linkHover');
      }
    }
  }

  .form {
    margin-bottom: 3rem !important;
    padding-top: 0;
    overflow: initial;
    padding-bottom: 1rem !important;
    &__submit {
      margin-top: 2.6rem;
      margin-bottom: 1.6rem;
    }
  }

  &__bottom {
    padding: 0 2rem 2rem;
    margin-right: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .button {
      flex: 1;
      max-width: 24rem;
      @include respond-down-to-media-breakpoint(small) {
        max-width: 15rem;
      }
    }
  }
}

.user-sign-up-step3 {
  &__infos {
    margin: 2rem 0 2rem 5.2rem;
    color: map-get($colors, "primary");
    font-family: $font-primary;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &__info-phone {
    margin: 2rem 0;
  }

  .form {
    padding-top: 0;
  }
}
