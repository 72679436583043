.preview-toast {
  display: flex;
  flex: 0.8;
  align-items: center;
  position: relative;
  z-index: 1;
  padding: 1rem 0;

  &__body {
    display: flex;
    position: relative;
    justify-content: flex-end;
    max-width: 50rem;
    overflow-wrap: break-word;
    @media screen and (max-width: $mobileWidth) {
      min-width: 100%;
    }
  }
}
