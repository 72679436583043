.billing-analyse-evolution {
    background-color: #fff;
    &__nbSelected {
    margin-right: 2rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center ;
        span {
        margin-right: 2rem
        }
    }

    th, td {

        &:nth-child(2) {
            padding-left: 6px;
        }
    }

    .billing-synthesis__title {
        padding: 2rem 0 0 1.3rem;        
    }

    .table-pagination {
        padding-bottom: 2rem;;
    }
    
    &_downloadBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
        &-button {
            display: flex;
            justify-content: space-around;
            align-items: center;

            &-icon {
            height: 2.5rem;
            width: 2.4rem;
            margin-right: 1rem;
            color: map-get($colors, 'primary');
            }
        }
        &__date-picker{
            height: 4.8rem;
            width: 31.5rem;
            margin-left: 1.6rem
        }
    }
    @media screen and (max-width: $mobileWidth) {
        .billing-analyse-evolution {
          &_downloadBar {
            flex-direction: column-reverse;
            &__date-picker{
                margin-top: 0.5rem;
            }
          }
        }
      } 
}
