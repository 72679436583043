.loading:not(table .loading):not(.loading__button),
.no-result {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.loading__button {
  margin: 0;
}

.loading__link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4rem;
}
