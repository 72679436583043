.activity-doughnut-chart-section {
  min-height: 25.8rem;
  width: 100%;
  padding: 1.2em 2.5em;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
    0 2px 4px 0 rgba(17, 95, 251, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__right#activity-doughnut-chart-section__right {
    width: 60%;
    max-width: 24.8rem;
    p {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.057rem;
      line-height: 1.8rem;
    }

    input.sz-form-group__input {
      padding-left: 1rem !important;
      padding-right: 2.5rem !important;
      text-overflow: ellipsis !important;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__checkbox {
    margin-left: 0.5em;
    margin-top: 1em;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .checkbox__label {
      margin-right: 0;
    }
  }

  &__title {
    font-size: 1.4rem;
    color: #030f40;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1.7rem;
  }

  &__total-price {
    font-size: 2rem;
    color: #030f40;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  &__supstring {
    vertical-align: super;
    font-size: 1.2rem;
  }

  &__checkbox {
    height: 1.8rem;
    min-width: 13.1rem;
    color: #030f40;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem;
  }

  &__select {
    width: 100%;
    color: map-get($colors, 'blue1');
    font-family: $font-primary;
    font-size: 1.6rem;
    height: 28rem;
    overflow-y: scroll;
    // sds overrides
    .css-vs1oli-control {
      border-color: map-get($colors, 'blue3');
    }

    &:hover {
      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'action');
      }
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .activity-doughnut-chart-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.3 0;

    &__right#activity-doughnut-chart-section__right {
      width: 100%;
    }

    .input-group {
      width: auto !important;
    }
  }
}
