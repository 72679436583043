.layout-connection-with-img {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 6rem;
  background-color: map-get($colors, "white");
  color: #000d42;
  padding-top: 4rem;

  @include respond-down-to-media-breakpoint(large) {
    display: block;
    height: auto;
    padding-top: 0;
  }

  @include respond-down-to-media-breakpoint(small) {
    padding: 0 1.5rem;
  }

  &__body {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
    }
  }

  &__logo {
    z-index: 1;
    padding: 2rem 6rem 0;
    background-color: map-get($colors, "white");
    font-size: 1.2rem;
    font-family: $font-primary-bold;

    @include respond-down-to-media-breakpoint(small) {
      padding: 2rem 2rem 0;
    }

    img {
      height: 3.249rem;
      margin-bottom: 0.5rem;
    }
  }

  &__msg {
    position: relative;
    padding: 3rem 2rem;
    font-size: 3.8rem;
    line-height: 4rem;

    @include respond-down-to-media-breakpoint(large) {
      bottom: 0;
      font-size: 3rem;
      line-height: 3rem;
    }

    @include respond-down-to-media-breakpoint(small) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  &__subtitle {
    position: relative;
    font-size: 3.2rem;
    line-height: 4rem;

    @include respond-down-to-media-breakpoint(large) {
      bottom: 0;
      font-size: 2.8rem;
      line-height: 3rem;
    }

    @include respond-down-to-media-breakpoint(small) {
      font-size: 2.4rem;
      line-height: 2.5rem;
    }
  }

  &__msg2 {
    font-family: $font-primary-bold;
    line-height: 4rem;

    @include respond-down-to-media-breakpoint(large) {
      font-size: 3rem;
      line-height: 3rem;
    }

    @include respond-down-to-media-breakpoint(small) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  &__left {
    width: 50%;
    min-width: 720px;
    margin-left: 2.8%;
    background: #fff;
    border-radius: 3px;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
      min-width: unset;
      margin: 0;
    }

    form {
      margin-top: auto;

      .form {
        @include respond-down-to-media-breakpoint(small) {
          padding: 2rem 1rem;
        }

        margin: 0 2.5rem 4rem;
        box-shadow: none;
      }
    }

    .login-wrapper {
      form {
        @include respond-down-to-media-breakpoint(large) {
          margin-top: 0;
          margin-bottom: 3rem;
        }
      }
    }
  }

  &__right {
    width: 50%;
    align-self: center;

    @include respond-down-to-media-breakpoint(large) {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }

  &__center {
    margin-left: 0;
    &__header {
      text-align: center;
    }

    &__subtitle {
      font-size: 1.8rem;
      font-style: italic;
    }
  }

  &__man-walking {
    max-width: 100%;
    align-items: center;
  }
}
