.selected-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 41.7rem;
  width: 100%;
  margin-bottom: 2rem;
  border: 0.1rem solid map-get($colors, 'borderFrameColor');
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.1rem 0.3rem 0 rgba(17, 95, 251, 0.1);
  background-color: map-get($colors, 'white');

  div {
    width: 37.7rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: center;
  }

  &.full {
    align-items: flex-start;
    height: auto;

    div {
      width: 100%;
    }
  }
  &--active {
    display: none;
  }
}

.css-1emorkm-option {
  background-color: #fff;
  color: #030f40;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-weight: normal;
}

.css-1emorkm-option:hover {
  background-color: #f4f6fb;
}
