/**
 * More detail about grid specification here :
 * https://suezsmartsolutions.invisionapp.com/console/SUEZ_PCE_UI-ckd4l3jbz08lw012dp9ufgqv7/ckdyhb0bg00xd01vhb6urnp9v/play
 */

@mixin grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2.4rem;
  width: $desktopWidth;
  margin: 0 auto;
}

@mixin grid-tablet {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  column-gap: 1.6rem;
  width: $tabletWidth;
  margin: 0 auto;
}

@mixin grid-mobile {
  display: grid;
  grid-template-columns: auto;
  column-gap: 0;

  max-width: 100%;
  padding: 1rem;
}
