.react-datepicker-wrapper {
  width: 100%;
  line-height: 3em;
  font-size: 1.5rem;
  .datepicker-input {
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
    border: 1px solid map-get($colors, 'gray2');
    color: map-get($colors, 'datePickerBorder');
  }
  .datepicker-input:focus-visible {
    outline: 0;
    border-bottom: 0.2rem solid map-get($colors, 'tableHead');
  }
}

.react-datepicker-popper {
  z-index: 9 !important;
}

.calendar-content {
  .react-datepicker__month-container {
    width: 35rem;
    float: none;
    .react-datepicker__header {
      background-color: map-get($colors, 'white');
      .react-datepicker__current-month {
        font-size: 1.5rem;
        text-transform: capitalize;
      }
      .react-datepicker__day-names {
        margin: 8px 0 0 0;
        .react-datepicker__day-name {
          width: 4rem;
          font-size: 1.5rem;
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__day {
        width: 4rem;
        line-height: 3rem;
        font-size: 1.5rem;
      }
      .react-datepicker__day--selected {
        background-color: map-get($colors, 'paginationBorder');
      }
      .react-datepicker__day--disabled {
        cursor: not-allowed;
      }
    }
  }
  .react-datepicker__input-time-container {
    width: 100%;
    margin: 0.8rem 0;
    text-align: center;
    line-height: 2.7rem;
    font-size: 1.5rem;
  }
}

.react-datepicker {
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px;
    padding: 15px;
    border: none;
}

.react-datepicker__header {
    background: white;
    border: none;
    padding: 0!important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 2rem;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    width: 7.5rem!important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    background-color: map-get($colors, 'primaryButtonColor');
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border:none;
    color: map-get($colors, 'datePickerBorder');
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: map-get($colors, 'datePickerBorder');
}

.react-datepicker__navigation--previous::before,
.react-datepicker__navigation--next::before {
    border-color: #fff;
}

.react-datepicker__current-month {
    font-size: 1.1rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.5rem;
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
    color: #333;
    font-size: 14px;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__month--selected,
.react-datepicker__month--keyboard-selected {
    background-color: map-get($colors, 'paginationBorder');
    color: #fff;
}

.react-datepicker__month-text:active,
.react-datepicker__quarter-text:active {
    transform: scale(0.98);
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background: map-get($colors, 'paginationBorder');
    font-weight: normal;
    color:black;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background: map-get($colors, 'paginationBorder');
    font-weight: normal;
    color:black;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: map-get($colors, 'paginationBorder');
    font-weight: normal;
    color:black;
}
