._dropdown-input {
  display: flex;
  align-items: center;
  gap: 1px;

  .searchBar_select {
    min-width: 10rem;
    width: fit-content;
  }
  .input-string {
    border: 1px solid map-get($colors, 'gray2');
    color: #484848;
    height: 47px;
    font-size: 1.6rem;
    outline: none;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(3, 15, 64, 0.25);
      border-color: map-get($colors, 'blue2') !important;
    }
  }
}
