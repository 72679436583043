.footer {
  background-color: map-get($colors, "primary");

  &__container {
    margin: auto;
    padding: 1.5rem 2.5%;
    width: 100%;

    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
      padding: 1.5rem;
    }

    @include respond-to-between-media-breakpoint(small, large) {
      padding: 1.5rem 2.5%;
    }
  }

  &__left {
    .logo {
      width: 156px;
      margin-left: 2.5rem;
    }
  }

  &__links {
    display: flex;
    justify-content: space-around;
    align-self: center;
    width: 100%;

    @include respond-down-to-media-breakpoint(small) {
      // Grid is useful in this situation (2 columns for links).
      display: grid;
      grid-template-columns: repeat(2, 11rem);
      grid-gap: 0 2rem;
      text-align: center;
    }

    a {
      @extend .text-white;
      font-size: 1.4rem;
      font-weight: bold;

      @include respond-down-to-media-breakpoint(small) {
        margin: 0 0 1.5rem;
        font-size: 1.4rem;
      }
    }
  }

  &__bottom {
    position: absolute;
    left: 0rem;
    bottom: 0rem;
    width: 100%;
    margin-bottom: 0;
  }
}
