.MyCommunications {
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
    .DateInput {
      width: 75%;
    }
    .SingleDatePicker_picker {
      z-index: 3;
    }
  }
  .submits-btns {
    justify-content: center;
    display: flex;
    button {
      &:last-child {
        margin: 0 1rem;
      }
      width: 30%;
    }
  }

  .tox-tinymce {
    border-radius: 2px;
    border: 1px solid map-get($colors, 'Mischka');
  } 

  &__table {
    .DateRangePicker div.DateInput {
      width: 63px;
      .DateInput_input {
        text-align: center;
      }
    }
    
    th {
      text-align: center;
      &.startAt, &.expireAt {
        min-width: 145px;
      }
      &.status, &.allUsers {
        min-width: 130px;
      }
      &.title {
        min-width: 255px;
      }
      &.username {
        min-width: 210px;
      }
      &.company {
        min-width: 190px;
      }
      &.controlButtons {
        min-width: 52px;
      }
      .custom-input {
        text-align: center;
      }
    }

    tbody {
      text-align: center;
    }
  }

  .update-message {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
  }

  @media screen and (max-width: $mobileWidth) {
    .deactivate-checkbox {
      margin-block-start: 2rem;
    }
  }
}



.communication-popup.modal-body {
  img {
    width: 100%;
  }
}
