.collapse-table {
  &__title {
    display: flex;
    align-items: center;
    height: 3.6rem;
    font-size: 1.4rem;
  }

  &__icon {
    font-family: $font-primary;
    height: 1rem;

    & path {
      fill: map-get($colors, 'primary');
    }
  }

  & .Sz-pagination {
    display: flex;
    justify-content: center;
    height: 6.1rem;
    margin-bottom: 0;
  }
}
