.search-with-list {
  padding: 1.5rem;
  background-color: map-get($colors, 'white');

  &__ok {
    button {
      width: 100%;
      height: 4.8rem;
    }
  }

  &__count {
    margin: 1.2rem 0;
    color: map-get($colors, 'gray500');
    font-family: $font-primary;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  &__tooMuch {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
}
