.gauge-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.6rem;
  height: 100%;

  &__title {
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    font-family: $font-primary-medium;
    font-size: 2.4rem;
  }
  &__body {
    display: flex;
    flex: 3;
    align-items: center;
    &__info {
      font-family: $font-primary-medium;
      font-size: 1.6rem;
    }
  }

  .semicircle-percent-value {
    font-family: $font-primary-bold;
    font-size: 3.5rem;
  }

  circle {
    transition: stroke-dashoffset 0.8s ease 0s, stroke-dasharray 0.8s ease 0s,
      stroke 0.8s !important;
  }
}
