.accordion-custom,
.accordion-item {
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
  0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
  .card-header {
    background-color: map-get($colors, 'white');
    cursor: pointer;
    border-bottom: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-header.active {
    background-color: map-get($colors, 'primary');
    color: map-get($colors, 'white');
    transition: 0.3s ease-in-out;
    svg.icon {
      path {
        fill: map-get($colors, 'white');
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.accordion-item {
  .card-body {
    padding: 0;
  }
}
