.expand-perimeter {
    &__backgroundcolor {
        background-color: map-get($colors, 'mainBackground');
    }
    &__title {
        margin-bottom: 1.75rem;
        font-family: $font-primary-bold;
        font-size: 3.2rem;
    }
    &__info {
        font-size: 1.6rem;
        width: 100%;
        color: map-get($colors, 'primary');
    }
    &__switchButtonContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.7rem;
        padding-left: 0.7rem;
      }
    &__switchTab {
        display: flex;
    
        .button {
          padding: 0;
          margin-bottom: 0.5rem;
        }
        @include respond-down-to-media-breakpoint(small) {
          flex-direction: column ;
          align-items: center;
          width: 100%;
        }
    }
    &__switchButton {
        height: 3.6rem !important;
        width: 10.8rem !important;
        margin-right: 0.5rem !important;
        font-family: $font-primary-bold !important;
        font-size: 1.2rem !important;
        letter-spacing: 0.05rem;
        outline: 0 !important;
    
        span {
          font-family: $font-primary-bold !important;
        }
    
        &_width {
          width: 20rem !important;
        }
    }
    &__container{
        font-size: 1.6rem;
        margin-top: 2em;
    }
    &__form-container{
        background: map-get($colors, 'white'); 
        padding: 5rem 5rem 7rem 5rem;
        margin-top: 2rem;
        width:60%;
        @include respond-down-to-media-breakpoint(small) {
            width: 100%;
          }
    }
        &__form-inline  {
            display: flex;
            flex-direction: row;
            padding-top: 2rem;
            @include respond-down-to-media-breakpoint(small) {
              flex-direction: column;
            }
            .form__label > div:first-child {
                width: 100%;
                display: flex;
              }
              .tooltip-icon {
                &__text {
                  @include respond-down-to-media-breakpoint(large) {
                    left: -3rem;
                  }
                }
              }
              .input_mandatory_indicator {
                flex: 1;
                text-align: left;
                align-self: auto;
              }
        }
    &__input-inline {
        flex: 1;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .form {
        margin-bottom: 3rem !important;
        padding-top: 0;
        overflow: initial;
        padding-bottom: 1rem !important;
        &__submit {
          margin-top: 2.6rem;
          margin-bottom: 1.6rem;
        }
      }
    
      &__bottom {
        padding: 0 2rem 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .button {
          flex: 1;
          max-width: 24rem;
          @include respond-down-to-media-breakpoint(small) {
            max-width: 15rem;
          }
        }
    }

    &__alert {
        font-size: 1.8rem;
        height: 4.5rem;
    }
    &__btn-container{
        padding-top: 1rem;
        position: relative;
        float: right;
    }
    &__result-info span  { display: inline-block;}
    &__multi-info {
      margin: 2rem 0rem 2rem 0rem;
    }
    &__left {
      min-height: 12rem;
      padding-bottom: 3.6rem;

      border-right: 0.2rem dashed  map-get($colors, 'blue1');
      &-container{
        position: absolute;
        top: 30%;
        left: 20%;
        @include respond-down-to-media-breakpoint(small) {
            left: 0 !important;
            top: 0 !important;
            margin: 2rem;
            border-right: 0rem !important
        }
      }
      @include respond-down-to-media-breakpoint(small) {
        border-right: 0rem !important
      }
    }
    &__right {
      min-height: 12rem;     
      &-container{
        background-color: map-get($colors, 'white');
        padding-bottom: 1rem ;
        min-height: 12rem;
        justify-content: center;
        align-items: center;
        position: absolute;
        text-align: center;
        padding-top: 1rem;
        @include respond-down-to-media-breakpoint(small) {
          width: 100%;
        }
      }
      &-button{
        width: 29rem !important;
        height: 5rem !important;
        .button {
          flex: 1;
          @include respond-down-to-media-breakpoint(small) {
            max-width: 15rem;
          }
        }
        &-icon{
          margin-right: 0 !important;
          margin-left: 1rem !important;
          width: 5rem;
        }
      }
      &-filename {
        min-height: 2rem;
      }
      @include respond-down-to-media-breakpoint(small) {
        width: 100%;
        min-height: 20rem ;
      }
    }
  &__result{
    padding: 3rem;
  }
  &__resultdBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    margin: 2rem 0 0;
    @include respond-down-to-media-breakpoint(small) {
      flex-direction: column;
    }
    &-button {
      display: flex;
      justify-content: space-around;
      align-items: center;

      &-icon {
        height: 2.5rem;
        width: 2.4rem;
        margin-right: 1rem;
        color: map-get($colors, 'primary');
      }
    }
  }
  &__resultText {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
  }
  &__table {
    margin-top: 1.5rem;
    .table {

      > table {

        > thead {

          th {
            padding-left: 10px;
            text-align: center;

            input {
              background: #fff !important;
            }

            &.selection {
              width: 1px;
            }
            &.numFacture {
              min-width: 140px;
            }
            &.numDebiteur {
              min-width: 140px;
            }
            &.statut {
              min-width: 240px;
            }
            &.commentaire {
              min-width: 280px;
            }
           
          }
        }

        tbody {

          td {
            text-align: center;
            &:first-child {
              padding-left: 10px;
            }
          }
        }
      }
    }

    
  }
  &__inputTable{
    margin-top: 1.5rem;
    .table {
        padding: 0rem 10rem 0rem 10rem ;
        @include respond-down-to-media-breakpoint(small) {
          padding: 0;
        }
      > table {

        > thead {

          th {
            padding-left: 10px;
            text-align: center;

            input {
              background: #fff !important;
            }

            &.selection {
              width: 1px;
            }
            &.numFacture {
              min-width: 120px;
            }
            &.numDebiteur {
              min-width: 120px;
            }
           
          }
        }

        tbody {

          td {
            text-align: center;
            &:first-child {
              padding-left: 10px;
            }
          }
        }
      }
    }

    
  }
}