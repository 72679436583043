.notifications {
  &__results {
    margin-block-start: 4rem;
  }

  &__info-text {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
  }

  &__table {
    td {
      text-align: center;
    }
    .date {
      width: 15rem;
    }
  
    .type {
      width: 15rem;
    }
  
    .detail {
      width: 90rem;
    }
  
    .controlButtons {
      width: 15rem;
    }

    &__notRead {
      font-weight: bold;
    }

    &__ButtonCell {
      display: flex;
      justify-content: end;
    }
  }
}
