.newPassword {
  background-color: map-get($colors, 'mainBackground');

  .screen-container-simple-layout {
    height: 100vh;
    padding-block-end: 0;

    .top-advice {
      margin: 0;
      padding: 0;
    }

    .layout-connection {
      margin-block-start: 6rem;
    }
  }

  .form {
    box-shadow: none;
  }

  &__cases {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    li  {
      color: map-get($colors, 'blue5');
      font-size: 1.2rem;
      font-family: $font-primary;
      line-height: 1.2rem;
      margin: 0.2rem 0;
    }
  }

  &__infoLibelle {
    color: map-get($colors, 'blue5');
    font-size: 1.2rem;
    font-family: $font-primary;
    text-align: center;
    line-height: 1.2rem;
    margin: 2.4rem 0;
  }
}
