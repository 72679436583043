.year-selector {
  display: flex;
  width: 19.6rem;
  height: 4rem;
  flex-direction: row;
  &__left-btn,
  &__right-btn {
    flex: 1;
  }

  &__btn {
    padding: 0;
    width: 100%;
    height: 100%;
    svg {
      margin-right: 0;
    }
  }
  &__icon {
    width: 0.8rem;
    height: 1.3rem;
  }

  &__label {
    display: flex;
    flex: 2.5;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
  &__second-year{
    border-top: 0.2rem solid map-get($colors, 'blue4');
  }
  &__right-btn {
    align-items: flex-end;
  }
}
