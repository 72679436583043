$decreased-height: 4.6rem;
$transition: all 0.2s ease-out;
$margin-top-content: 2.5rem;

.perimeter-filter {
  display: flex;
  align-items: center;
  height: $decreased-height;
  width: 100%;
  max-width: $desktopWidth;
  margin: 0 auto;
  padding: 0 2.5%;
  background-color: map-get($colors, 'primary');
  color: map-get($colors, 'white');
  font-family: $font-primary-bold;
  font-size: 1.4rem;
  line-height: 2.3rem;
  &.in-maintenance {
    background-color: map-get($colors, 'tablesortEnabled');
    padding-left: 0 !important;
    padding-right: 0 !important;
    > div {
      background-color: map-get($colors, 'tablesortEnabled');
      .perimeter-filter__reset {
        background-color: map-get($colors, 'tablesortEnabled');
      }
    }
  }
  &.impersonate {
    color: map-get($colors, 'primary');
    background-color: map-get($colors, 'impersonateBackground');
  }
  &__select {
    position: relative;

    .smart-select__search {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    width: 100%;

    @include respond-down-to-media-breakpoint(large) {
      flex-direction: column;
    }
  }

  &__title {
    display: flex;
    margin-right: 4%;
    font-size: 1.6rem;
  }

  &__organizations,
  &__institutions {
    width: 32%;
    margin-right: 2%;

    @include respond-to-between-media-breakpoint(small, large) {
      width: 50%;
      margin: 0 0 2rem 0;
    }

    @include respond-down-to-media-breakpoint(small) {
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }

  &__display {
    display: flex;
    justify-content: flex-end;
    max-width: $desktopWidth;
    margin: 0 auto;
    font-size: 1.4rem;
    line-height: 1.9rem;
    &.impersonate {
      background-color: map-get($colors, 'impersonateBackground');
    }
    &-right {
      position: absolute;
      right: 2.5%;
    }

    &-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 9.2rem;
      padding: 0.3rem 0.5rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      cursor: pointer;
      &.impersonate {
        background-color: map-get($colors, 'impersonateBackground');
      }
      &:hover {
        color: map-get($colors, 'linkHover');
      }

      &:hover path {
        fill: map-get($colors, 'linkHover');
      }
    }
    &-button-impersonate {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 9.2rem;
      padding: 0.3rem 0.5rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: map-get($colors, 'impersonateBackground');
      color: map-get($colors, 'primary');
      cursor: pointer;
      &:hover {
        color: map-get($colors, 'linkHover');
      }

      svg {
        // To have more weights.

        path {
          fill: map-get($colors, 'primary');
        }
      }

      &:hover path {
        fill: map-get($colors, 'linkHover');
      }
    }
  }

  &__icon {
    height: 1.5rem;
    width: 1.5rem;

    & path {
      fill: map-get($colors, 'white');
    }
  }

  &__select-icon {
    margin-right: 2rem;

    & path {
      fill: map-get($colors, 'primary');
    }
  }

  &__reset {
    height: $decreased-height;
    margin-top: $margin-top-content;
    border: none;
    background-color: map-get($colors, 'primary');
    color: map-get($colors, 'white');
    text-decoration: none;
    outline: none !important;
    &:hover {
      color: map-get($colors, 'linkHover');
    }

    &:hover &-icon {
      path {
        fill: map-get($colors, 'linkHover');
      }
    }

    &-icon {
      margin-left: 1.2rem;
      path {
        fill: map-get($colors, 'white');
      }
    }

    @include respond-down-to-media-breakpoint(large) {
      align-self: flex-start;
      margin-top: 0;
    }
  }
  &__reset-impersonate {
    height: $decreased-height;
    margin-top: $margin-top-content;
    border: none;
    background-color: map-get($colors, 'impersonateBackground');
    color: map-get($colors, 'primary');
    text-decoration: none;
    outline: none !important;
    &:hover {
      color: map-get($colors, 'linkHover');
    }

    &:hover &-icon {
      path {
        fill: map-get($colors, 'linkHover');
      }
    }

    &-icon {
      margin-left: 1.2rem;
      path {
        fill: map-get($colors, 'primary');
      }
    }

    @include respond-down-to-media-breakpoint(large) {
      align-self: flex-start;
      margin-top: 0;
    }
  }

  // SWITCH BETWEEN "INCREASED" / "DECREASED" MODE

  &.increased-mode {
    height: auto;
    transition: $transition;

    .perimeter-filter__value {
      display: none;
    }

    .perimeter-filter__content {
      padding-bottom: 0.75rem;
    }

    .perimeter-filter__title {
      align-self: center;
      margin-top: 2rem;

      @include respond-down-to-media-breakpoint(large) {
        align-self: flex-start;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    .perimeter-filter__organizations,
    .perimeter-filter__institutions {
      @include respond-down-to-media-breakpoint(large) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.decreased-mode {
    transition: $transition;
    height: 4.7rem;

    .perimeter-filter__select,
    .perimeter-filter__reset-impersonate,
    .perimeter-filter__reset {
      display: none;
    }

    .perimeter-filter__organizations,
    .perimeter-filter__institutions {
      display: flex;
      align-items: center;

      @include respond-down-to-media-breakpoint(large) {
        display: none;
      }
    }
  }

  &__display.increased-mode {
    .perimeter-filter__plus {
      display: none;
    }
  }

  &__display.decreased-mode {
    .perimeter-filter__minus {
      display: none;
    }
  }
}
